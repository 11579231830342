<template>
    <div class="col-12">
    <div class="content-body border-round h-full">
        
        <div class="grid p-fluid">
            <div class="col-6 col-offset-3" >
                <Divider type="dotted"/>
                <!-- // countries -->
                <form @submit.prevent="submitForm">           
                    <!-- // Login ID -->
                    <h2>Login ID</h2>
                    <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-file"></i>
                    </span>
                        <InputText type="text" v-model="username" />
                    </div>

                    <!-- // Password -->
                    <h2>Password</h2>
                    <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-file"></i>
                    </span>
                        <InputText type="password" v-model="password" />
                    </div> 

                    <div class="notification is-danger" v-if="errors.length">
                        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                    </div>
                    
                    <Divider type="dotted"/>
                    <Button label="Login Now" severity="success" type="submit"/>
                </form>
            </div>
            
        </div>
    </div>
    </div>
</template>

<script>
    import axios from 'axios'
    export default{
        name:"LogIn",
        data() {
            return {
                username:'',
                password:'',
                errors:[],
            }
        },
        methods:{
            async submitForm(){
                axios.defaults.headers.common['Authorization'] = ''
                localStorage.removeItem('token')
                
                const formData = {
                    username: this.username,
                    password: this.password,
                }
                
                await axios
                    .post('/api/v1/token/login/', formData)
                    .then(response => {
                        const token = response.data.auth_token
                        this.$store.commit('setToken', token)
                        axios.defaults.headers.common['Authorization'] = 'Token ' + token
                        localStorage.setItem('token', token)
                    })    
                    .catch(error=>{
                        if (error.response) {
                            for (const property in error.response.data){
                                this.errors.push(`${property}:${error.response.data[property]}`)
                            }
                        } else if (error.message){
                            this.errors.push("Something went wrong. Please try again!")
                        
                        
                        }
                    })
                
                await axios
                    .get('/api/v1/users/me')
                    .then(response=>{
                        this.$store.commit('setUser',{'id': response.data.id, 'username': response.data.username})
                        localStorage.setItem('username', response.data.username)
                        localStorage.setItem('userid', response.data.id)
                        localStorage.setItem('isAuthenticated', true)
                        this.$router.push('/')
                        console.log("here user")
                    })
                    .catch(error=>{
                        console.log(error)
                    })
            }
        }
    }
</script>