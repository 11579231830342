<template>
  <div class="grid nested-grid">
      <!-- main grid col-10 -->
      <div class="col-11">
          <div class="grid">
              <!-- sub grid col-12 -->
              <div class="col-12">
                  <div class="content-header border-round bg-teal-100 h-full">
                    <h1>Error Listing - Pending Update Mistake Made By</h1>
                  </div>
              </div>

              <div class="col-12">
                  <div class="content-body border-round h-full">
                  <!-- table here -->
                    <DataTable v-model:filters="filters" 
                                        :value="tickets"  
                                        paginator :rows="50" 
                                        dataKey="id" 
                                        removableSort 
                                        tableStyle="min-width: 50rem" 
                                        filterDisplay="row"
                                        :globalFilterFields="[]">
                                        
                        <template #empty> No customers found. </template>
                        <template #loading> Loading data. Please wait. </template>                        
                        <!-- end -->
                      
                        <Column field="id" header="Ticket ID" frozen class="font-bold" style="min-width:50px">
                            <template #body="{ data }">
                                {{ data.id }}
                            </template>
                        </Column>
                        <Column field="requester.username" header="Requester" sortable frozen class="font-bold" style="min-width:50px">
                            <template #body="{ data }">
                                {{ data.requester.username }}
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Requester" />
                            </template>
                        </Column>
                        <Column field="country.short_name" header="Country" sortable style="min-width: 50px">
                            <template #body="{ data }">
                                {{ data.country.short_name }}
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Country" />
                            </template>
                        </Column>
                        <Column field="role_1" header="Role 1" style="min-width: 50px">
                            <template #body="{ data }">
                                {{ data.role_1 }}
                            </template>
                        </Column>
                        <Column field="role_2" header="Role 2" style="min-width: 50px">
                            <template #body="{ data }">
                                {{ data.role_2 }}
                            </template>
                        </Column>
                        <Column field="qcfile" header="QC File" sortable style="min-width: 50px">
                            <template #body="{ data }">
                                {{ data.qcfile }}
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="QC File" />
                            </template>
                        </Column>
                        <Column field="created_at" header="Created At" sortable style="min-width: 50px">
                            <template #body="{ data }">
                                {{ data.created_at }}
                            </template>
                        </Column>
                        <Column field="status" header="Ticket Status" style="min-width: 50px">
                            <template #body="{ data }">
                                {{ data.status }}
                            </template>
                        </Column>
                        <Column field="qcresult" header="QC Result" style="min-width: 50px">
                            <template #body="{ data }">
                                {{ data.qcresult == null ? "N/A" : data.qcresult}}
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="QC Result" />
                            </template>
                        </Column>
                        <Column field="mistaked_made_by.username" header="Mistake Made By" style="min-width: 50px">
                            <template #body="{ data }">
                                {{ data.mistaked_made_by == null ? "N/A" : data.mistaked_made_by.username}}
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="QC Result" />
                            </template>
                        </Column>
                        <Column field="id" header=""  style="min-width: 100px">
                            <template #body="{ data }">
                                <Button label="View" severity="secondary" @click="$router.push({name: 'TicketDetail', params: {id: data.id}})"/>
                            </template>
                        </Column>                        
                    </DataTable>
                  </div>
              </div>
          </div>
      </div>
      <!-- main grid col-2 -->
      <div class="col-1">
          <div class="grid">
              <!-- sub grid col-12 -->
              <div class="col-12">
                  <div class="card flex justify-content-center">
                      <Button label="Go to Dashboard" />
                  </div>
              </div>
          </div>
      </div>
  
  </div>
</template>

<script>
    import axios from 'axios'
    import { FilterMatchMode } from 'primevue/api';

    export default {
        name:"TicketQueuePendingUpdate",
        data() {
            return {
                tickets:[],
                filters : {
                        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                        'requester.username' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                        'country.short_name' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                        'qcfile' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                        'role_2' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                        'created_at' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                        'qcresult': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                        'mistaked_made_by.username': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                }
            }
        },
        mounted(){
            this.getTickets()     
        },
        methods:{
            async getTickets(){
                await axios
                    .get(`/api/v1/qcticket/`, {params:{filter_type:"TicketQueuePendingUpdate"}})
                    .then(response=>{
                        this.tickets=response.data
                        console.log(this.tickets)
                    })
                    .catch(error=>{
                        console.log(error)
                    })
            },
        },
    }

</script>

<style scoped>
	/* Some custom styles to beautify this example */
    .content-header{
        padding: 15px;
        background: #dbdfe5;
        text-align: center;
    }
    .content-body{
      padding:15px;
      margin-top:15px;
      background: #909791;
      min-height: 750px;
    }
    .content-header.cust-border{
      border-style: solid;
      margin-bottom:1px;
    }
    .custom_font {
    color: var(--teal-900);; 
    font-weight: 700;
    }   
</style>