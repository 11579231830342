<template>
  
  <div class="grid nested-grid">
      <!-- main grid col-10 -->
      <div class="col-11">
          <div class="grid">
              <!-- sub grid col-12 -->
              <div class="col-12">
                  <div class="content-header border-round bg-teal-100 h-full">
                    <h2>Countries Assignments</h2>
                  </div>
              </div>

              <div class="col-12">
                  <div class="content-body border-round h-full">
                  <!-- table here -->
                    <DataTable v-model:filters="filters" :value="assignments"  paginator :rows="2" dataKey="id" removableSort tableStyle="min-width: 50rem" filterDisplay="row"
                                :globalFilterFields="['role_actual.username', 
                                                      'role_s_one.username',
                                                      'role_s_two.username',
                                                      'role_s_three.username',
                                                      'role_s_test.username',]">
                        <!-- standard DataTable slot -->
                        <template #header>
                            <div class="flex justify-content-end">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                                </span>
                            </div>
                        </template>
                        <template #empty> No customers found. </template>
                        <template #loading> Loading customers data. Please wait. </template>                        
                        <!-- end -->
                      
                        <Column field="country.name" header="Country" sortable frozen class="font-bold" style="min-width: 200px">
                            <template #body="{ data }">
                                {{ data.country.name }}
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Search by country name" />
                            </template>
                        </Column>
                        <Column field="country.category" header="Category" sortable frozen class="font-bold" style="min-width: 200px">
                            <template #body="{ data }">
                                {{ data.country.category }}
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Search by country name" />
                            </template>
                        </Column>
                        <Column field="role_actual.username" header="Role Actual" sortable style="min-width: 300px">
                            <template #body="{ data }">
                                {{ data.role_actual.username }}
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Search by country name" />
                            </template>
                        </Column>
                        <Column field="role_s_one.username" header="Shadow 1" sortable style="min-width: 200px">
                            <template #body="{ data }">
                                {{ data.role_s_one.username }}
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Search by country name" />
                            </template>
                        </Column>
                        <Column field="role_s_two.username" header="Shadow 2" sortable  style="min-width: 200px">
                            <template #body="{ data }">
                                {{ data.role_s_two.username }}
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Search by country name" />
                            </template>
                        </Column>
                        <Column field="role_s_three.username" header="Shadow 3" sortable style="min-width: 200px">
                            <template #body="{ data }">
                                {{ data.role_s_three.username }}
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Search by country name" />
                            </template>
                        </Column>
                        <Column field="role_s_test.username" header="Shadow 4" sortable style="min-width: 300px">
                            <template #body="{ data }">
                                {{ data.role_s_test.username }}
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Search by country name" />
                            </template>
                        </Column>

                    </DataTable>
                  </div>
              </div>
          </div>
      </div>
      <!-- main grid col-2 -->
      <div class="col-1">
          <div class="grid">
              <!-- sub grid col-12 -->
              <div class="col-12">
                  <div class="card flex justify-content-center">
                      <Button label="Update" />
                  </div>
                  <Divider />
                  <div class="card flex justify-content-center">

                  </div>
              </div>
          </div>
      </div>
  </div>
</template>


<script>
    import axios from 'axios'
    import { FilterMatchMode } from 'primevue/api';
    
    export default {
        name:'',
        props:{},
        components:{
        },

        data(){return{
          assignments:[],
          filters : {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                'role_actual.username' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                'role_s_one.username' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                'role_s_two.username' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                'role_s_three.username' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                'role_s_test.username' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                'country.category': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
         }
          
        }},
        mounted(){
          this.getAssignments()
        },
        computed:{},
        watch:{},
        methods:{
          async getAssignments(){
            await axios
              .get(`/api/v1/assignment/`)
              .then(response=>{
                this.assignments = response.data
                console.log("here")
                console.log(response.data)
                console.log(response.data)
                console.log(this.assignments)
              })
              .catch(error=>{
                console.log(error)
              })
          },
        },
        emits:[],
    }

</script>

<style scoped>
	/* Some custom styles to beautify this example */
    .content-header{
        padding: 15px;
        background: #dbdfe5;
        text-align: center;
    }
    .content-body{
      padding:15px;
      margin-top:15px;
      background: #909791;
      min-height: 750px;
    }
    .content-header.cust-border{
      border-style: solid;
      margin-bottom:1px;
    }
</style>