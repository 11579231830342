<template>
        <Menubar :model="items" class="layout-topbar">
    
            <template #start>
                <a href="/" style="text-decoration:none;
                                color:lightgreen">
                    <h2>Operation Portal</h2>
                </a>
            </template>

            <template #item="{item, props, hasSubmenu, root}" >
                <div v-if="$store.state.isAuthenticated">
                    <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                        <a v-ripple :href="href" v-bind="props.action" @click="navigate">
                            <span :class="item.icon" />
                            <span class="ml-2">{{ item.label }}</span>
                        </a>
                    </router-link>

                    <a v-else v-ripple v-bind="props.action">
                        <span :class="item.icon" />
                        <span class="ml-2">{{ item.label }}</span>
                        <i v-if="hasSubmenu" :class="['pi pi-angle-down text-primary', { 'pi-angle-down ml-2': root, 'pi-angle-right ml-auto': !root }]"></i>
                    </a>
                </div>
            </template>

            <template #end>
                <div v-if="$store.state.isAuthenticated">
                    <span>Welcome {{username}}</span> 
                    <Button class="mr-1" label="Log Out" severity="danger" @click="logout()"/>
                    <Button class="mr-1" label="User Profile" severity="primary"/>
                </div>


                <div v-else>
                    <Button label="Sign In" severity="primary" @click="$router.push({name:'LogIn'})"/>
                </div>
            </template>
        </Menubar>
    
</template>

<script>
    import axios from 'axios'

    export default {
        name:'Navbar',
        data() {
            return {
                username: this.$store.state.user.username,
                items:[
                        // master data
                        {
                            label: 'Master Data',
                            items: [
                                {
                                    label: 'Countries Assignments',
                                    icon: 'pi pi-flag',
                                    route: {name:'AssignmentList'},
                                },
                            ]
                        },

                        // Ticket
                        {
                            label: 'Tickets',
                            icon: 'pi pi-ticket',
                            items: [
                                // form
                                {
                                    label: 'Form Submittion',
                                    icon: 'pi pi-ticket',
                                    route: {name:'QCTicketForm'},
                                },
                                // listing
                                {
                                    label: 'Listing',
                                    items: [
                                            {
                                                label: 'In Queue Listing',
                                                icon: 'pi pi-ticket',
                                                route: {name:'TicketQueueInQueue'},
                                            },
                                            {
                                                label: 'Completed Listing',
                                                icon: 'pi pi-ticket',
                                                route: {name:'TicketQueueCompleted'},
                                            },
                                            {
                                                label: 'Error - Pending Update Mistake_By',
                                                icon: 'pi pi-ticket',
                                                route: {name:'TicketQueuePendingUpdate'},
                                            },
                                            {
                                                label: 'Error - Updated Mistaked_By',
                                                icon: 'pi pi-ticket',
                                                route: {name:'TicketQueueUpdated'},
                                            },
                                            {
                                                label: 'Full Listing',
                                                icon: 'pi pi-ticket',
                                                route: {name:'TicketQueueFillList'},
                                            },
                                    ]
                                },
                                // dashboard
                                {
                                    label: 'Dashboard',
                                    items: [
                                        {
                                            label: 'Today',
                                            icon: 'pi pi-chart-bar',
                                            items:[
                                                    {
                                                        label: 'Ticket Status Dashboard',
                                                        icon: 'pi pi-chart-bar',
                                                        route: {name:'DashboardTicketStatus'},
                                                    },
                                                    {
                                                        label: 'QC Performance Dashboard',
                                                        icon: 'pi pi-chart-pie',
                                                        route: {name:'DashboardQCPerformance'},
                                                    },
                                                ]
                                        },
                                        {
                                            label: 'This Month',
                                            icon: 'pi pi-chart-bar',
                                            items:[
                                                    {
                                                        label: 'Ticket Status Dashboard',
                                                        icon: 'pi pi-chart-bar',
                                                        route: {name:'DashboardTicketStatusThisMonth'},
                                                    },
                                                    {
                                                        label: 'QC Performance Dashboard',
                                                        icon: 'pi pi-chart-pie',
                                                        route: {name:'DashboardQCPerformanceThisMonth'},
                                                    },
                                                ]
                                        },
                                        {
                                            label: 'This Year',
                                            icon: 'pi pi-chart-bar',
                                            items:[
                                                    {
                                                        label: 'Ticket Status Dashboard',
                                                        icon: 'pi pi-chart-bar',
                                                        route: {name:'DashboardTicketStatusThisYear'},
                                                    },
                                                    {
                                                        label: 'QC Performance Dashboard',
                                                        icon: 'pi pi-chart-pie',
                                                        route: {name:'DashboardQCPerformanceThisYear'},
                                                    },
                                                ]
                                        },

                                    ]
                                },
                            ]
                        },
                        // Posts
                        {
                            label: 'Posts',
                            icon: 'pi pi-circle-fill',
                            items: [
                                {
                                    label: 'Form Submittion',
                                    icon: 'pi pi-inbox',
                                    route: {name:'PostForm'},
                                },
                                //General
                                {
                                    label: 'General',
                                    icon: 'pi pi-circle',
                                    route: {name:'PostGeneralList'},
                                },
                                //Tasks
                                {
                                    label: 'Routine Tasks Listing',
                                    icon: 'pi pi-building',
                                    route: {name:'PostTaskList'},
                                },
                                //Systems
                                {
                                    label: 'Systems',
                                    icon: 'pi pi-desktop',
                                    items:[
                                            {
                                                label: 'System Bugs Listing',
                                                icon: 'pi pi-chart-bar',
                                                route: {name:'PostSystemBugList'},
                                            },
                                            {
                                                label: 'System Change Requests Listing',
                                                icon: 'pi pi-chart-pie',
                                                route: {name:'PostSystemChangeRequestList'},
                                            },
                                        ]
                                },
                            ]
                        },
                        // Data Recon
                        {
                            label: "Data Reconilication",
                            icon: 'pi-list',
                            route: {name:'DataReconilication'},
                        },
                        // Budget & Forecast
                        {
                            label: "Budget & Forecast",
                            icon: 'pi pi-pound',
                            route: {name:'BudgetAndForecast'},
                        },
                        // Approval Everything
                        {
                            label: "Approve Everything",
                            icon: 'pi pi-link',
                            route: {name:'BudgetAndForecast'}
                        }
                    ]
            }
        },
        methods:{
            async logout() {
                await axios
                    .post('/api/v1/token/logout/')
                    .then(response=> {
                        console.log('Logged Out')
                    })
                    .catch(error =>{
                        console.log(JSON.stringify(error))
                    })
                axios.defaults.headers.common['Authorization'] = ''
                localStorage.removeItem('token')
                localStorage.removeItem('username')
                localStorage.removeItem('userid')
                localStorage.removeItem('isAuthenticated')
                this.$store.commit('removeToken')
                this.$router.push('/')
            }
        }
    }
</script>

<style scoped>
.layout-topbar {
    position: fixed;
    height: 5rem;
    z-index: 997;
    left: 0;
    top: 0;
    width: 100%;
    padding: 0 2rem;
    background-color: var(--surface-card);
    transition: left .2s;
    display: flex;
    align-items: center;
    box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014;
}
</style>