<template>
  <div class="grid nested-grid">
      <!-- main grid col-10 -->
      <div class="col-11">
          <div class="grid nested-grid">
            <!-- sub grid col-12 -->
            <!-- QC performance by Staff -->
            <div class="col-4">
                <div class="content-header border-round bg-teal-100">
                    <h1>Chart 1: QC performance by Staff By This Year</h1>
                </div>
                <div class="grid">
                    <div class="col-12" >
                      <apexcharts id="chart_1_chartOptions" width="500" height="500" type="bar" :options="chart_1_chartOptions" :series="chart_1_series"></apexcharts>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <div class="content-header border-round bg-teal-100">
                    <h1>Table</h1>
                </div>
                <div class="grid">
                    <div class="col-12" >
                      <DataTable v-model:filters="filters" 
                        :value="chart_1_data"  
                        paginator :rows="8" 
                        dataKey="id" 
                        removableSort 
                        tableStyle="min-width: 25rem" 
                        filterDisplay="row"
                        :globalFilterFields="[]">
                                        
                        <template #empty> No customers found. </template>
                        <template #loading> Loading data. Please wait. </template>                        
                        <!-- end -->
                      
                        <Column field="x" header="Mistake Made By" frozen class="font-bold" sortable style="min-width:50px">
                            <template #body="{ data }">
                                {{ data.x }}
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Search by country name" />
                            </template>
                        </Column>
                        <Column field="y" header="Total of Submitted Ticket Today" sortable frozen class="font-bold" style="min-width:50px">
                            <template #body="{ data }">
                                {{ data.y }}
                            </template>
                        </Column>
                      </DataTable>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="content-header border-round bg-teal-100">
                    <h1>Table Details</h1>
                </div>
                <div class="grid">
                    <div class="col-12" >
                      <DataTable v-model:filters="filters" 
                        :value="chart_1_detail_data"  
                        paginator :rows="8" 
                        dataKey="id" 
                        removableSort 
                        tableStyle="min-width: 25rem" 
                        filterDisplay="row"
                        :globalFilterFields="[]">
                                        
                        <template #empty> No customers found. </template>
                        <template #loading> Loading data. Please wait. </template>                        
                        <!-- end -->
                      
                        <Column field="id" header="Ticket ID" frozen class="font-bold" sortable style="min-width:50px">
                            <template #body="{ data }">
                                {{ data.id }}
                            </template>
                        </Column>
                        <Column field="x" header="Mistake Made By" sortable frozen class="font-bold" style="min-width:50px">
                            <template #body="{ data }">
                                {{ data.x }}
                            </template>
                        </Column>
                        <Column field="short_name" header="Country" sortable frozen class="font-bold" style="min-width:50px">
                            <template #body="{ data }">
                                {{ data.short_name }}
                            </template>
                        </Column>

                        <Column field="qcfile" header="QC File" sortable frozen class="font-bold" style="min-width:50px">
                            <template #body="{ data }">
                                {{ data.qcfile }}
                            </template>
                        </Column>
                        <Column field="status" header="Status" sortable frozen class="font-bold" style="min-width:50px">
                            <template #body="{ data }">
                                {{ data.status }}
                            </template>
                        </Column>
                        <Column field="role_1" header="Role 1" sortable frozen class="font-bold" style="min-width:50px">
                            <template #body="{ data }">
                                {{ data.role_1 }}
                            </template>
                        </Column>
                        <Column field="role_2" header="Role 2" sortable frozen class="font-bold" style="min-width:50px">
                            <template #body="{ data }">
                                {{ data.role_2 }}
                            </template>
                        </Column>
                      </DataTable>
                    </div>
                </div>
            </div>
            <Divider />

          </div>
      </div>

      <!-- main grid col-2 -->
      <div class="col-1">
          <div class="grid">
              <!-- sub grid col-12 -->
              <div class="col-12">
                  <div class="card flex justify-content-center">

                  </div>
                  <div class="card flex justify-content-center">

                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api';

export default {
  name: 'DashboardTicketStatus',
  components: {
    apexcharts: VueApexCharts,
  },
  mounted(){
    this.getTickets()
  },
  data: function() {
    return {
      assignments:[],
      chart_1_chartOptions: {
        chart: {
          id: 'basic-bar',
          title: {
            text: "Chart-1"
          },
        },
        plotOptions: {
          bar: {
            horizontal: true
          },
        },
        legend: {
            showForSingleSeries:true,
            
        },
        xaxis: {
          type:'category',
          min: 0,
        },
        yaxis: {
        },
      },
      chart_1_series: [{
        name: 'chart-1',
        data: []
      }],
      chart_2_chartOptions: {
        chart: {
          id: 'basic-bar',
          title: {
            text: "Chart-2"
          },
        },
        plotOptions: {
          bar: {
            horizontal: true
          },
        },
        legend: {
            showForSingleSeries:true,
            
        },
        xaxis: {
          type:'category',
          min: 0,
        },
        yaxis: {
        },
      },
      chart_2_series: [{
        name: 'chart-2',
        data: []
      }],
      chart_3_chartOptions: {
        chart: {
          id: 'basic-bar',
          title: {
            text: "Chart-3"
          },
        },
        plotOptions: {
          bar: {
            horizontal: true
          },
        },
        legend: {
            showForSingleSeries:true,
            
        },
        xaxis: {
          type:'category',
          min: 0,
        },
        yaxis: {
        },
      },
      chart_3_series: [{
        name: 'chart-3',
        data: []
      }],

      chart_1_data:[],
      chart_1_detail_data:[],
      chart_2_data:[],
      chart_2_detail_data:[],
      chart_3_data:[],
      chart_3_detail_data:[],
      filters : {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            'x': { value: null, matchMode: FilterMatchMode.STARTS_WITH },

      }
   }
  },
 methods:{
    async getTickets(){

      // CountBy function
      const countBy = (arr, fn) =>
        arr.map(typeof fn === 'function' ? fn : val => val[fn]).reduce((acc, val) => {
          acc[val] = (acc[val] || 0) + 1;
          return acc;
        }, {});

      await axios
        .get(`/api/v1/qcticket/`, {params:{filter_date:"This_Year"}})
        .then(response=>{
            this.tickets=response.data

            // chart-1 ----------------------------------------------------------------------          
            // format data into the JSON format, please console.log to check the result if needed
            let formatteddata = this.tickets.filter(function(ticket) {
              return ticket.qcresult === "Has_Error";
            }).map((e, i) => {
              return {
                short_name: e.country.short_name,
                count:1,
                qcfile:e.qcfile,
                status:e.status,
                role_1:e.role_1,
                role_2:e.role_2,
                id:e.id,
                x: e.mistaked_made_by== null ? "Havent Assign Yet" : e.mistaked_made_by.username,
              };
            });

            var chart_1_result_countby = countBy(formatteddata, x => x.x)

            var chart_1_result_arr = [];
            // convert the countby into JSON format
            for (var key in chart_1_result_countby) {
                if (chart_1_result_countby.hasOwnProperty(key)) {
                    chart_1_result_arr.push({"x":key, "y":chart_1_result_countby[key]} );
                }
            }
            
            this.chart_1_data=chart_1_result_arr
            this.chart_1_detail_data=formatteddata
            //return the series result
            this.chart_1_series = [
              {
                name:"Count of Submitted Tickets",
                data:chart_1_result_arr
              }
            ]
            // chart-1 ----------------------------------------------------------------------




        })
        .catch(error=>{
            console.log(error)
        })
    },
 }
}
</script>
