import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import { isProxy, toRaw } from 'vue';
import Vuex from 'vuex'

import HomeView from '../views/HomeView.vue'
import LogIn from '../views/LogIn.vue'
import AssignmentList from '../views/AssignmentList.vue'
import QCTicketForm from '../views/QCTicketForm.vue'
import TicketQueueInQueue from '../views/TicketQueueInQueue.vue'
import TicketQueueCompleted from '../views/TicketQueueCompleted.vue'
import TicketQueuePendingUpdate from '../views/TicketQueuePendingUpdate.vue'
import TicketQueueUpdated from '../views/TicketQueueUpdated.vue'
import TicketQueueFullList from '../views/TicketQueueFullList.vue'
import TicketDetail from '../views/TicketDetail.vue'
import TicketEdit from '../views/TicketEdit.vue'
import DashboardTicketStatus from '../views/DashboardTicketStatus.vue'
import DashboardTicketStatusThisMonth from '../views/DashboardTicketStatusThisMonth.vue'
import DashboardTicketStatusThisYear from '../views/DashboardTicketStatusThisYear.vue'
import DashboardQCPerformance from '../views/DashboardQCPerformance.vue'
import DashboardQCPerformanceThisMonth from '../views/DashboardQCPerformanceThisMonth.vue'
import DashboardQCPerformanceThisYear from '../views/DashboardQCPerformanceThisYear.vue'
import PostForm from '../views/PostForm.vue'
import PostSystemBugList from '../views/PostSystemBugList.vue'
import PostSystemDetails from '../views/PostSystemDetails.vue'
import PostSystemChangeRequestList from '../views/PostSystemChangeRequestList.vue'
import PostTaskList from '../views/PostTaskList.vue'
import DataReconilication from '../views/DataReconilication.vue'
import BudgetAndForecast from '../views/BudgetAndForecast.vue'
import PostGeneralList from '../views/PostGeneralList.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/log-in',
    name: 'LogIn',
    component: LogIn
  },
  {
    path: '/assignment-list',
    name: 'AssignmentList',
    component: AssignmentList,
    meta: {
      requireLogin: true
    }
  },  
  {
    path: '/data-reconilication',
    name: 'DataReconilication',
    component: DataReconilication,
    meta: {
      requireLogin: true
    }
  },  
  {
    path: '/budget-forecast',
    name: 'BudgetAndForecast',
    component: BudgetAndForecast,
    meta: {
      requireLogin: true
    }
  },  
  {
    path: '/qc-ticket-form',
    name: 'QCTicketForm',
    component: QCTicketForm,
    meta: {
      requireLogin: true
    }
  },  
  {
    path: '/ticket-queue-in-queue',
    name: 'TicketQueueInQueue',
    component: TicketQueueInQueue,
    meta: {
      requireLogin: true
    }
  },  
  {
    path: '/ticket-detail/:id',
    name: 'TicketDetail',
    component: TicketDetail,
    meta: {
      requireLogin: true
    }
  },  
  {
    path: '/ticket-detail/:id/edit',
    name: 'TicketEdit',
    component: TicketEdit,
    
    meta: {
      requireLogin: true
    }
  },  
  {
    path: '/dashboard-ticket-status',
    name: 'DashboardTicketStatus',
    component: DashboardTicketStatus,
    meta: {
      requireLogin: true
    }
  },  
  {
    path: '/dashboard-ticket-status-this-month',
    name: 'DashboardTicketStatusThisMonth',
    component: DashboardTicketStatusThisMonth,
    meta: {
      requireLogin: true
    }
  },  
  {
    path: '/dashboard-ticket-status-this-year',
    name: 'DashboardTicketStatusThisYear',
    component: DashboardTicketStatusThisYear,
    meta: {
      requireLogin: true
    }
  },  

  {
    path: '/dashboard-qc-performance',
    name: 'DashboardQCPerformance',
    component: DashboardQCPerformance,
    meta: {
      requireLogin: true
    }
  },  
  {
    path: '/dashboard-qc-performance-this-month',
    name: 'DashboardQCPerformanceThisMonth',
    component: DashboardQCPerformanceThisMonth,
    meta: {
      requireLogin: true
    }
  },  
  {
    path: '/dashboard-qc-performance-this-year',
    name: 'DashboardQCPerformanceThisYear',
    component: DashboardQCPerformanceThisYear,
    meta: {
      requireLogin: true
    }
  },  
  {
    path: '/ticket-queue-completed',
    name: 'TicketQueueCompleted',
    component: TicketQueueCompleted,
    meta: {
      requireLogin: true
    }
  },  
  {
    path: '/ticket-queue-pending-update',
    name: 'TicketQueuePendingUpdate',
    component: TicketQueuePendingUpdate,
    meta: {
      requireLogin: true
    }
  },  
  {
    path: '/ticket-queue-updated',
    name: 'TicketQueueUpdated',
    component: TicketQueueUpdated,
    meta: {
      requireLogin: true
    }
  },  
  {
    path: '/ticket-queue-fulllist',
    name: 'TicketQueueFillList',
    component: TicketQueueFullList,
    meta: {
      requireLogin: true
    }
  },  
  {
    path: '/post-form',
    name: 'PostForm',
    component: PostForm,
    meta: {
      requireLogin: true
    }
  },  
  {
    path: '/post-system-bug-list',
    name: 'PostSystemBugList',
    component: PostSystemBugList,
    meta: {
      requireLogin: true
    }
  },  
  {
    path: '/post-system-change-request-list',
    name: 'PostSystemChangeRequestList',
    component: PostSystemChangeRequestList,
    meta: {
      requireLogin: true
    }
  },  
  {
    path: '/post-system-details/:id',
    name: 'PostSystemDetails',
    component: PostSystemDetails,
    meta: {
      requireLogin: true
    }
  },  
  {
    path: '/post-task-list',
    name: 'PostTaskList',
    component: PostTaskList,
    meta: {
      requireLogin: true
    }
  },  
  {
    path: '/post-general-list',
    name: 'PostGeneralList',
    component: PostGeneralList,
    meta: {
      requireAuth: true,
      requireLogin: true
    }
  },  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  
  const to_fullpath = to.fullPath
  const from_fullpath = from.fullPath

  console.log('to: ' + to_fullpath)
  console.log('from: ' + from_fullpath)

  if (!localStorage.isAuthenticated && to.name != "LogIn"){
    console.log('not login yet')
    next({name: 'LogIn'})
  } else {
    next()
  }
})

export default router
