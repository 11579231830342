import { createStore } from 'vuex'

export default createStore({
  state: {
    isAuthenticated : false,
    token           : '',
    user            : {id:0, username:''},
    toasts          :[],
  },
  getters: {
    checkIsAuthenticated () { return localStorage.getItem('isAuthenticated') },
  },
  mutations: {
    addToast(state, toast){
        state.toasts.push(toast)
    },
    clearToast(state){
      // const index = state.toasts.findIndex((toast)=> toast.summary === summary); // find toast
      state.toasts=[]//remove toast from array
    },
    initializeStore(state) {
      if (localStorage.getItem('token')){
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
        state.user.username=localStorage.getItem('username')
        state.user.id=localStorage.getItem('userid')
      }else {
        state.token = ""
        state.isAuthenticated = false
        state.user.id=0
        state.user.username=""
      }
    },
    setToken(state, token){
      state.token = token
      state.isAuthenticated = true
    },
    removeToken(state){
      state.token = ''
      state.isAuthenticated = false
    },
    setUser(state,user){
        state.user=user
    },
  },
  actions: {
  },
  modules: {
  }
})
