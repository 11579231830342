<template> 
  <div class="grid nested-grid">
      <Toast />
      <!-- main grid col-10 -->
      <div class="col-12">
          <div class="grid">
              <div class="col-12" style="color: var(--cyan-900); font-weight: 700;">
                <div class="content-body border-round h-full">
                    <div class="grid">
                            <!-- ticket details -->
                            <div class="col-6">
                                <div class="content-header border-round bg-teal-100">
                                    <h1>Ticket Details (Edit Mode)</h1>
                                </div>
                                <form @submit.prevent="submitUpdateTicket()">
                                    <div class="grid">
                                        <!-- Submit Updated Ticket Button -->
                                        <div class="col-6" >
                                            <!-- // countries -->
                                                <h2>Requester</h2>
                                                <div class="p-inputgroup">
                                                <span class="p-inputgroup-addon">
                                                    <i class="pi pi-user"></i>
                                                </span>
                                                    <InputText v-model=requester disabled placeholder="Disabled" class="custom_font"/>
                                                </div>
                                                
                                                <!-- // files -->
                                                <h2>Country</h2>
                                                <div class="p-inputgroup">
                                                <span class="p-inputgroup-addon">
                                                    <i class="pi pi-flag"></i>
                                                </span>
                                                    <InputText v-model="country" disabled placeholder="Disabled" class="custom_font"/>
                                                </div>
                                            
                                                <h2>Role 1</h2>
                                                <div class="p-inputgroup">
                                                <span class="p-inputgroup-addon">
                                                    <i class="pi pi-user"></i>
                                                </span>
                                                    <InputText v-model="role_1" disabled placeholder="Disabled" class="custom_font"/>
                                                </div>                        

                                                <h2>Role 2</h2>
                                                <div class="p-inputgroup">
                                                <span class="p-inputgroup-addon">
                                                    <i class="pi pi-user"></i>
                                                </span>
                                                    <InputText v-model="role_2"  disabled placeholder="Disabled"  class="custom_font"/>
                                                </div>    
                                                
                                                <h2>QC Result</h2>
                                                <div class="p-inputgroup">
                                                <span class="p-inputgroup-addon">
                                                    <i class="pi pi-bookmark"></i>
                                                </span>
                                                    <InputText v-model="qcresult" disabled placeholder="" class="custom_font"/>
                                                </div>    

                                                <h2>**Not In Use Field**</h2>
                                                <div class="p-inputgroup">
                                                <span class="p-inputgroup-addon">
                                                    <i class="pi pi-bookmark"></i>
                                                </span>
                                                    <InputText disabled placeholder="Not in Use" class="custom_font"/>
                                                </div>  
                                        </div>
                                        <div class="col-6" >
                                            <!-- // countries -->
                                                <h2>QC File</h2>
                                                <div class="p-inputgroup">
                                                <span class="p-inputgroup-addon">
                                                    <i class="pi pi-file"></i>
                                                </span>
                                                    <InputText v-model="qcfile" disabled placeholder="Disabled" class="custom_font"/>
                                                </div>
                                                
                                                <!-- // files -->
                                                <h2>Status</h2>
                                                <div class="p-inputgroup">
                                                <span class="p-inputgroup-addon">
                                                    <i class="pi pi-bookmark"></i>
                                                </span>
                                                    <InputText v-model="status" disabled placeholder="Disabled" class="custom_font"/>
                                                </div>
                                            
                                                <h2>Created At</h2>
                                                <div class="p-inputgroup">
                                                <span class="p-inputgroup-addon">
                                                    <i class="pi pi-clock"></i>
                                                </span>
                                                    <InputText v-model="created_at" disabled placeholder="Disabled" class="custom_font"/>
                                                </div>                        

                                                <h2>Ticket ID</h2>
                                                <div class="p-inputgroup">
                                                <span class="p-inputgroup-addon">
                                                    <i class="pi pi-hashtag"></i>
                                                </span>
                                                    <InputText v-model="ticket_id" disabled placeholder="Disabled" class="custom_font"/>
                                                </div>    

                                                <h2>Origin Mistake Made By</h2>
                                                <div class="p-inputgroup">
                                                <span class="p-inputgroup-addon">
                                                    <i class="pi pi-user"></i>
                                                </span>
                                                    <InputText v-model="existing_mistake_made_by" disabled placeholder="-" class="custom_font"/>
                                                </div>

                                                <h2 style="color:red">New Mistake Made By</h2>
                                                <div class="p-inputgroup">
                                                <span class="p-inputgroup-addon">
                                                    <i class="pi pi-user"></i>
                                                </span>
                                                    <Dropdown v-model="mistake_made_by" :options="users" optionLabel="username" placeholder="Select User" class="w-full md:w-14rem" />
                                                </div>  
                                                <Divider />      
                                                <div class="card flex justify-content-center">
                                                    <Button type="submit" label="Update Ticket Now" rounded/> 
                                                </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <!-- ticket notes -->
                            <div class="col-4">
                                <div class="content-header border-round bg-teal-100">
                                    <h1>Ticket Notes</h1>
                                </div>
                                <div>
                                    <!-- Add Note -->
                                    <!-- KIV: Convert this to component and emit back to here -->
                                    <div v-show="showAddNote" >
                                        <span class="p-float-label">
                                        <Textarea v-model=new_note autoResize rows="5" cols="30" class="col-12 mt-1"/>
                                        <label>Type your note here...</label>
                                        </span>
                                        <!-- <Byt @:click="submitNewNote()" type="submit" class="btn btn-secondary btn-lg">+ Add a note</Byt> -->
                                        <Button label="Submit Note" @:click="submitNewNote()" rounded severity="primary" text class="col-3 col-offset-4"/> 
                                    </div>

                                    <!-- if notes is NA, then show this div -->
                                    <template v-if="!notes.length" >
                                        <Card style="text-align: center;" class="m-2">
                                            <template #title>Note is not available!</template>
                                            <template #content>
                                                <p class="m-0">
                                                    Please click "Add Note" at top right
                                                </p>
                                            </template>
                                        </Card>
                                    </template>
                                    <!-- loop the notes -->
                                    <ScrollPanel style="width: 100%; height: 700px">
                                        <div v-for="note in notes" v-bind:key="note.id" style="text-align: left;">
                                            <Fieldset class="m-1">
                                                <template #legend>
                                                    <div class="flex align-items-center gap-2 px-2">
                                                        <span class="pi pi-tag"></span>
                                                        <span>{{note.id}}</span>
                                                        <span class="pi pi-user"></span>
                                                        <span>{{note.created_by.username}}</span>
                                                        <span class="pi pi-clock"></span>
                                                        <span>{{note.created_at}}</span>
                                                    </div>
                                                </template>
                                                <p class="m-1" >
                                                {{note.note_details}}
                                                </p>
                                            </Fieldset>
                                        
                                        </div>
                                    </ScrollPanel>
                                </div>
                            </div>
                            <!-- ticket event -->        
                            <div class="col-1">
                                <div class="content-header border-round bg-teal-100">
                                    <h3>Log</h3>
                                </div>
                                <Card style="text-align: center;" class="m-2">
                                    <template #title>Coming Soon!</template>
                                </Card>
                            </div>

                            <div class="col-1">
                                <div class="content-header border-round bg-teal-100">
                                    <h3>Action</h3>
                                </div>
                                <div class="grid">
                                <!-- sub grid col-12 -->
                                <div class="col-12">
                                    <!-- Add Note Button -->
                                    <div class="card flex justify-content-center">
                                        <Button label="Add New Note" @:click="toggleAddNote()" rounded text/>
                                    </div>
                                    <Divider />
                                </div>
                            </div>
                            </div>  
                    </div>
                </div>
              </div>
          </div>
      </div>

  </div>
</template>

<script>
    import { toast } from 'vue3-toastify';
    import axios from 'axios'
    import 'vue3-toastify/dist/index.css';

    export default {
        name:"TicketDetail",
        data(){
            return {
                ticket: Object,
                requester: '',
                country:'',
                role_1:'',
                role_2:'',
                qcfile:'',
                status:'',
                created_at:'',
                ticket_id:'',
                mistake_made_by:'',
                existing_mistake_made_by:'',
                qcresult:'',
                notes:[],
                showAddNote: false,
                new_note:'',
                users:[],
            }
        },
        mounted(){
            this.getBasicInfo()
        },
        methods: {
            async submitUpdateTicket(){
                console.log("submit Ticket bro")
                const mistake_made_by={
                    mistake_made_by:this.mistake_made_by.id
                }
                const ticketID=this.$route.params.id
                axios
                    .patch(`/api/v1/qcticket/${ticketID}/`, mistake_made_by)
                    .then(response=>{
                        this.$store.commit('addToast', {
                            severity: 'success',
                            summary: 'Success',
                            detail: 'This ticket has been updated',
                            life:3000
                        })
                        this.$router.push({
                                            name:'TicketDetail', 
                                            params: {id: this.$route.params.id}
                                                    }
                                        )
                    })
                    .catch(error=>{
                        console.log(error)
                    })
            },
            async submitNewNote() {
                
                const newnote = {
                    note: this.$route.params.id,
                    note_details: this.new_note,
                    created_by: parseInt(this.$store.state.user.id),
                }

                await axios
                    .post(`/api/v1/ticketnote/`, newnote)
                    .then(response => {
                        console.log(response.data)
                        // turn off the show add note section
                        this.showAddNote = false;
                        // pop out success toast
                        toast.info("Added Note. This page will auto refreshing in 3 sec!",
                                        {position: toast.POSITION.TOP_CENTER,})
                        // refresh the page
                        setTimeout(() => { 
                            window.location.reload(); 
                        }, 2000)
                    })
                    .catch(error =>{
                        console.log(error)
                    })
            },
            async toggleAddNote() {
                this.showAddNote = !this.showAddNote
            },
            async getBasicInfo() {

                const ticketID = this.$route.params.id
                
                // get the ticket info
                await axios
                .get(`/api/v1/qcticket/${ticketID}`)
                .then(response =>{
                    console.log(response.data)
                    this.ticket=response.data
                    this.requester=response.data.requester.username
                    this.country=response.data.country.short_name
                    this.role_1=response.data.role_1
                    this.role_2=response.data.role_2
                    this.created_at=response.data.created_at
                    this.ticket_id=response.data.id
                    this.qcfile=response.data.qcfile
                    this.status=response.data.status
                    if (response.data.mistaked_made_by === null) {

                    } else {
                        console.log("mistaked_made_by is not null")
                        this.existing_mistake_made_by=response.data.mistaked_made_by.username
                        // this.mistake_made_by=response.data.mistaked_made_by.username
                    }
                    console.log()
                    this.qcresult=response.data.qcresult
                    }
                )
                .catch(error => {
                    console.log(error)
                })

                // get the note info
                await axios
                .get(`/api/v1/ticketnote/?ticket_id=${ticketID}`)
                .then(response =>{
                    console.log(response.data)
                    this.notes=response.data
                })
                .catch(error=> {
                    console.log(error)
                })
                
                // get the user info
                await axios
                .get(`/api/v1/assignment/`)
                .then(response=>{
                    console.log("asd")
                    console.log(this.country)
                    for (let i = 0, len = response.data.length; i < len; i++) {
                        console.log(i)
                        
                        console.log(response.data[i].country.short_name)
                        console.log(this.country)
                        if (response.data[i].country.short_name === this.country) {
                            this.users.push(response.data[i].role_actual)
                            this.users.push(response.data[i].role_s_one)
                            this.users.push(response.data[i].role_s_two)
                            this.users.push(response.data[i].role_s_three)
                            this.users.push(response.data[i].role_s_test)
                            console.log(this.users)
                            break;
                            
                        }
                    }
                })
                .catch(error=>{
                    console.log(error)
                })
            }
        }
    }
</script>

<style scoped>
	/* Some custom styles to beautify this example */
    .content-header{
        padding: 15px;
        background: #dbdfe5;
        text-align: center;
    }
    .content-body{
      padding:10px;
      margin-top:10px;
      background: white;
      min-height: 350px;
    }
    .content-header.cust-border{
      border-style: solid;
      margin-bottom:1px;
    }

    .custom_font {
    color: var(--teal-900);; 
    font-weight: 700;
    }   

</style>