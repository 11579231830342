<template>
  <div class="grid nested-grid">
      <!-- main grid col-10 -->
      <div class="col-11">
          <div class="grid nested-grid">
              <!-- sub grid col-12 -->
              <div class="col-12">
                  <div class="content-header border-round bg-teal-100 h-full">
                    <h1>Ticket Submittion Form</h1>
                  </div>
              </div>

              <div class="col-12">
                <div class="content-body border-round h-full">
                    
                    <div class="grid p-fluid">
                        <div class="col-6 col-offset-3" >
                            <Divider type="dotted"/>
                            <!-- // countries -->
                            <form @submit.prevent="submitForm">
                                <h2>Country</h2>
                                <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">
                                    <i class="pi pi-flag"></i>
                                </span>
                                    <Dropdown v-model=selected_country :options="countries" optionLabel="name" placeholder="Select a Country" class="w-full md:w-14rem" />
                                </div>
                                
                                <!-- // files -->
                                <h2>Files</h2>
                                <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">
                                    <i class="pi pi-file"></i>
                                </span>
                                    <InputText type="text" v-model="selected_qcfile" />
                                </div>
                            
                                <h2>Role 1</h2>
                                <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">
                                    <i class="pi pi-user"></i>
                                </span>
                                    <Dropdown v-model=selected_role_1 :options="role_types" optionLabel="type" placeholder="Select Role 1" class="w-full md:w-14rem" />
                                </div>                        

                                <h2>Role 2</h2>
                                <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">
                                    <i class="pi pi-user"></i>
                                </span>
                                    <Dropdown v-model=selected_role_2 :options="role_types" optionLabel="type" placeholder="Select Role 2" class="w-full md:w-14rem" />
                                </div>    
                                <Divider type="dotted"/>
                                <Button label="Submit Ticket" severity="success" type="submit"/>
                            </form>
                        </div>
                        
                    </div>
                </div>
              </div>
          </div>
      </div>
      <!-- main grid col-2 -->
      <div class="col-1">
          <div class="grid">
              <!-- sub grid col-12 -->
              <div class="col-12">

              </div>
          </div>
      </div>
  </div>



</template>

<script>
    import axios from 'axios'

    
    export default {
        name:"QCTicketForm",
        data(){
            return {
                selected_country:'',
                selected_qcfile:'',
                selected_role_1:'',
                selected_role_2:'',
                countries:[],
                role_types:[
                    {type: "Actual"}, 
                    {type: "Shadow_1"},
                    {type: "Shadow_2"},
                    {type: "Shadow_3"},
                    {type: "Shadow_test"}
                ],
                items:[
                        {
                            label: 'Home',
                            icon: 'pi pi-home'
                        },
                    ],
            }
        },
        mounted(){
            this.getCountry()
        },
        methods:{
            async getCountry(){
                await axios
                    .get(`/api/v1/country/`)
                    .then(response=>{
                        this.countries=response.data
                    })
                    .catch(error=>{
                        console.log(error)
                    })
            },
            async submitForm(){
                // validate the selected_country whether is empty or not
                if(!this.selected_country){
                        toast.error("Country Field is empty!!",
                                    {autoClose: 3000,
                                    position: toast.POSITION.TOP_CENTER,})
                    return;
                }                
                
                // validate the selected_qcfile whether is empty or not
                if(!this.selected_qcfile){
                        toast.error("QC File Field is empty!!",
                                    {autoClose: 3000,
                                    position: toast.POSITION.TOP_CENTER,})
                    return;
                } else {
                    let text= this.selected_qcfile
                    let pattern = /[a-zA-Z]/g;
                    var result = (text.match(pattern));

                    if(!result){
                        toast.error("QC File Field has no valid value!!",
                                    {autoClose: 3000,
                                    position: toast.POSITION.TOP_CENTER,})
                       return;
                    }
                }

                if(!this.selected_role_1 || !this.selected_role_2){
                        toast.error("Role 1 or 2 cant be empty!!",
                                    {autoClose: 3000,
                                    position: toast.POSITION.TOP_CENTER,})
                        return;
                }
                
                // validate the selected_role_1 and selected_role_2 whether is same or not
                if(this.selected_role_1===this.selected_role_2){
                        toast.error("Role 1 cant be the same as Role 2!!",
                                    {autoClose: 3000,
                                    position: toast.POSITION.TOP_CENTER,})
                        return;
                }
                //Loop each files 
                for (let i=0; i < result.length; i++){

                    const qcticket={
                        requester: parseInt(this.$store.state.user.id),  
                        country: this.selected_country.id,
                        qcfile: result[i],
                        role_1: this.selected_role_1.type,
                        role_2: this.selected_role_2.type,
                    }

                    console.log(qcticket)

                    await axios
                        .post(`/api/v1/qcticket/`, qcticket)
                        .then(response=>{
                            this.$router.push({ name: 'TicketQueueInQueue'})
                            
                        })
                        .catch(error=>{
                            
                            console.log(error)
                        })
                }
            },
        }
    }
</script>

<style scoped>
	/* Some custom styles to beautify this example */
    .content-header{
        padding: 15px;
        background: #dbdfe5;
        text-align: center;
    }
    .content-body{
      padding:15px;
      margin-top:15px;
      background: white;
      min-height: 750px;
    }
    .content-header.cust-border{
      border-style: solid;
      margin-bottom:1px;
    }
</style>