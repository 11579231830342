<template>
  
  <div class="grid nested-grid">
      <Toast position="bottom-left"/>
      <!-- main grid col-10 -->
      <div class="col-12">
          <div class="grid">
              <div class="col-12" style="color: var(--cyan-900); font-weight: 700;">
                <form @submit.prevent="submitForm">
                    <div class="content-body border-round h-full">
                        <div class="grid">
                                <!-- Post -->
                                <div class="col-6">
                                    <div class="content-header border-round bg-teal-100">
                                        <h1>New Post</h1>
                                    </div>
                                    <div class="grid">
                                        <div class="col-7" >
                                            <Divider type="dotted"/>
                                            <!-- // Categeory -->
                                            <h2>Category</h2>
                                            <div class="p-inputgroup">
                                            <span class="p-inputgroup-addon">
                                                <i class="pi pi-box"></i>
                                            </span>
                                                <Dropdown v-model=category :options="type_category" required optionLabel="type" placeholder="Select" class="w-full md:w-14rem" />
                                            </div>
                                            
                                            <!-- // Sub Category -->
                                            <h2>Sub Category</h2>
                                            <div class="p-inputgroup">
                                            <span class="p-inputgroup-addon">
                                                <i class="pi pi-bookmark"></i>
                                            </span>
                                                <Dropdown v-model=subcat :options="type_subcat" optionLabel="type" placeholder="Select" class="w-full md:w-14rem" />
                                            </div>

                                            <!-- Priority -->
                                            <h2>Priority</h2>
                                            <div class="p-inputgroup">
                                            <span class="p-inputgroup-addon">
                                                <i class="pi pi-book"></i>
                                            </span>
                                                <Dropdown v-model=priority :options="type_priority" optionLabel="type" placeholder="Select" class="w-full md:w-14rem" />
                                            </div>    

                                            <!-- Due Date -->
                                            <h2>Due Date</h2>
                                            <div class="p-inputgroup">
                                            <span class="p-inputgroup-addon">
                                                <i class="pi pi-calendar-plus"></i>
                                            </span>
                                                <Calendar v-model="due_date" dateFormat="dd/mm/yy" />
                                            </div>    
                                            
                                            <Divider type="dotted"/>
                                        </div>
                                        <div class="col-5" >
                                            <Divider type="dotted"/>
                                            
                                            <!-- // Assingee -->
                                            <h2>Assignee</h2>
                                            <div class="p-inputgroup">
                                            <span class="p-inputgroup-addon">
                                                <i class="pi pi-user"></i>
                                            </span>
                                                <Dropdown v-model=assignee :options="type_users" optionLabel="username" placeholder="Select" class="w-full md:w-14rem" />
                                            </div>
                                            
                                            <!-- // Reviewer -->
                                            <h2>Reviewer</h2>
                                            <div class="p-inputgroup">
                                            <span class="p-inputgroup-addon">
                                                <i class="pi pi-user"></i>
                                            </span>
                                                <Dropdown v-model=reviewer :options="type_users" optionLabel="username" placeholder="Select" class="w-full md:w-14rem" />
                                            </div>

                                            <!-- // Approver -->
                                            <h2>Approver</h2>
                                            <div class="p-inputgroup">
                                            <span class="p-inputgroup-addon">
                                                <i class="pi pi-user"></i>
                                            </span>
                                                <Dropdown v-model=approver :options="type_users" optionLabel="username" placeholder="Select" class="w-full md:w-14rem" />
                                            </div>                        

                                            <!-- // Country -->
                                            <h2>Country</h2>
                                            <div class="p-inputgroup">
                                            <span class="p-inputgroup-addon">
                                                <i class="pi pi-flag"></i>
                                            </span>
                                                <MultiSelect v-model=country_tag :options="type_country" optionLabel="short_name" placeholder="Select" class="w-full md:w-14rem" />
                                            </div>       

                                            <Divider type="dotted"/>
                                        </div>
                                    </div>
                                </div>
                                <!-- Post Details -->
                                <div class="col-5">
                                    <div class="content-header border-round bg-teal-100">
                                        <h1>New Post Details</h1>
                                    </div> 

                                    <Divider type="dotted"/>
                                    
                                    <!-- // Title -->
                                    <h2>Title</h2>
                                    <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">
                                        <i class="pi pi-box"></i>
                                    </span>
                                        <div class="card flex justify-content-center">
                                            <Textarea v-model="title" required="true" v-tooltip="'Max character: 50'" autoResize rows="1" cols="100" maxlength="50"/>
                                        </div>
                                    </div>
                                    
                                    <!-- // Details -->
                                    <h2>Details</h2>
                                    <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">
                                        <i class="pi pi-bookmark"></i>
                                    </span>
                                        <div class="card flex justify-content-center">
                                            <Textarea v-model="details" v-tooltip="'Max character: 50'" autoResize rows="3" cols="100" maxlength="200"/>
                                        </div>
                                    </div>

                                    <!-- // Descriptions -->
                                    <h2>Description</h2>
                                    <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">
                                        <i class="pi pi-book"></i>
                                    </span>
                                        <div class="card flex justify-content-center">
                                            <Textarea v-model="description" v-tooltip="'Max character: 50'" autoResize rows="3" cols="100" maxlength="200"/>
                                        </div>
                                    </div>                        
                                    
                                    <!-- // Remarks -->
                                    <h2>Remarks</h2>
                                    <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">
                                        <i class="pi pi-calendar-plus"></i>
                                    </span>
                                        <div class="card flex justify-content-center">
                                            <Textarea v-model="remarks" v-tooltip="'Max character: 50'" autoResize rows="3" cols="100" maxlength="200"/>
                                        </div>
                                    </div>

                                    <Divider type="dotted"/>
                                </div>

                                <div class="col-1">
                                    <Button label="Submit Post Now" severity="success" type="submit" class="p-button-lg" />
                                </div>  
                        </div>
                    </div>
                </form>
              </div>
          </div>
      </div>
  </div>
</template>


<script>
    import axios from 'axios'
    
    export default {
        name:"PostForm",
        data(){
            return {
                type_category:[
                    {type: "System_Change_Request"}, 
                    {type: "System_Bug"},
                    {type: "General"},
                    {type: "Ad_Hoc_Requests"},
                    {type: "Routine_Tasks"}
                ],
                type_subcat:[
                    {type: "Module_A"}, 
                    {type: "Module_B"},
                    {type: "Module_C"},
                    {type: "Module_D"},
                    {type: "Module_E"}
                ],
                type_priority:[
                    {type: "Low"}, 
                    {type: "Medium"},
                    {type: "High"},
                    {type: "Urgent"},
                ],
                type_country:[],
                type_users:[],
                category:'',
                subcat:'',
                priority:'',
                due_date:'',
                title:'',
                details:'',
                description:'',
                remarks:'',
                requester:'',
                assignee:'',
                reviewer:'',
                approver:'',
                country_tag:'',

            }
        },
        mounted(){
            this.getChoicesData()
            // this.submitForm()
        },
        methods:{
            async getChoicesData(){
                

                
                // get the list of user from backend
                await axios
                .get(`/api/v1/users/`)
                .then(response=>{
                    this.type_users=response.data

                })
                .catch(error=> {
                    console.log(error)
                })
                
                 // get the list of country from backend
                await axios
                .get(`/api/v1/country/`)
                .then(response=>{
                    this.type_country=response.data
                })
                .catch(error=> {
                    console.log(error)
                })

                 // default the due_date = current date + 7 
                const date = new Date();
                const theDayOfTheMonthOnNextWeek = date.getDate() + 7;
                date.setDate(theDayOfTheMonthOnNextWeek)
                this.due_date = date
            },

            async submitForm(){


                if (this.category ===""){
                    confirm("Please select one category")
                    return
                }
                
                function formatDate(date = new Date()) {
                    const year = date.toLocaleString('default', {year: 'numeric'});
                    const month = date.toLocaleString('default', {
                        month: '2-digit',
                    });
                    const day = date.toLocaleString('default', {day: '2-digit'});

                    return [year, month, day].join('-');
                    }
                
                const post={
                    category:this.category.type,
                    subcat:this.subcat.type||"",
                    priority:this.priority.type||"",
                    due_date:formatDate(this.due_date),
                    title:this.title,
                    details:this.details,
                    description:this.description,
                    remarks:this.remarks,
                    requester:parseInt(this.$store.state.user.id),
                    assignee:this.assignee.id || "",
                    reviewer:this.reviewer.id || "",
                    approver:this.approver.id || "",
                    country_tag: this.country_tag=== '' ? '' : this.country_tag.map(function(el){return el.short_name}), 
                }
                console.log(post)
                await axios
                    .post(`/api/v1/post/`, post)
                    .then(response=>{
                        if (this.category.type==="System_Bug") {
                            this.$router.push({ name: 'PostSystemBugList'})
                        } else if (this.category.type==="System_Change_Request") {
                            this.$router.push({ name: 'PostSystemChangeRequestList'})
                        } else if (this.category.type==="General") {
                            this.$router.push({ name: 'PostGeneralList'})
                        } else if (this.category.type==="Routine_Task") {
                            this.$router.push({ name: 'PostTaskList'})
                        } 
                        
                    })
                    .catch(error=>{
                        console.log(error)
                    })
            }
        }
    }
</script>

<style scoped>
	/* Some custom styles to beautify this example */
    .content-header{
        padding: 15px;
        background: #dbdfe5;
        text-align: center;
    }
    .content-body{
      padding:15px;
      margin-top:15px;
      background: white;
      min-height: 750px;
    }
    .content-header.cust-border{
      border-style: solid;
      margin-bottom:1px;
    }
</style>