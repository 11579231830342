<template>
    
    <Navbar />
    <!-- <div id="watermark"></div> -->
    <div style="margin-top:90px">
      <router-view/>
    </div>
    
</template>


<script>
  import axios from 'axios'
  import Navbar from '@/components/Navbar.vue'

  export default {
        name:'App',
        props:{},
        components:{
          Navbar,
          
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
          // console.log(this.$store.state.user)
          if (this.$store.state.token){
            axios.defaults.headers.common['Authorization'] = "Token " + this.$store.state.token
          } else {
              axios.defaults.headers.common['Authorization'] = ""
          }
        },
        created(){
        },
        mounted(){
          // var textWatermark = 'Hon Foong Personal Project. Copyright All Rights Reserved.';
          // var fullTextWatermark = '';
          // var n = 10000;
          // for (var i = 0; i < n; i++) {
          //   fullTextWatermark+= ' ' + textWatermark;
          // }
          // document.getElementById('watermark').innerText = fullTextWatermark
        }
    }
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  color: #083663;
}

#watermark {
  color: rgba(128, 128, 128, 0.3);
  font-size: 50px;
  height: 100%;
  left: 0;
  line-height: 2;
  margin: 0;
  position: fixed;
  top: 0;
  transform: rotate(-30deg);
  transform-origin: 0 100%;
  width: 200%;
  word-spacing: 10px;
  z-index: 1;
  opacity: 0.5;
  pointer-events: none;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
