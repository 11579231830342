<template>

    <div class="card flex">
        <!-- Post -->
        <Card class="m-2"  style="width: 70em">
            <template #header></template>
            <template #title>Summary: General</template>
            <template #subtitle>Click the filter button below to genearte the listing</template>
            <template #content>
                <!-- Total Count - All -->
                <div>
                    <Button label="All" outlined style="width: 200px" @click="filter_posts('All')" />
                    <Badge class="m-2" :value="this.posts.length" size="large" severity="primary"></Badge>
                </div>
                <divider  />
            </template>
            <template #footer></template>
        </Card>

    </div>

    <divider/>  

    <div class="grid nested-grid">
        <!-- main grid col-11 -->
        <div class="col-12">
            <div class="grid">
                <!-- sub grid col-12 -->
                <!-- Header - Title -->
                <div class="col-12">
                    <div class="content-header border-round bg-teal-100 h-full">
                    <h1> General Listing</h1>
                    </div>
                </div>
                <!-- Body  -->
                <div class="col-12">
                    <div class="content-body border-round h-full">
                        <!-- table here -->
                        <DataTable v-model:filters="filters" 
                                            :value="selected_posts"  
                                            paginator :rows="50" 
                                            dataKey="id" 
                                            removableSort 
                                            tableStyle="min-width: 50rem" 
                                            filterDisplay="row"
                                            :globalFilterFields="[]">
                                            
                            <template #empty> No Data found. </template>
                            <template #loading> Loading data. Please wait. </template>                        
                            
                            <Column field="id" header="ID" frozen class="font-bold" style="min-width:50px">
                                <template #body="{ data }">
                                    {{ data.id }}
                                </template>
                            </Column>

                            <Column field="category" header="Category" sortable frozen class="font-bold" style="min-width:250px">
                                <template #body="{ data }">
                                    {{ data.category }}
                                </template>
                                <template #filter="{ filterModel, filterCallback }">
                                    <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
                                </template>
                            </Column>

                            <Column field="subcat" header="Sub Category" sortable frozen class="font-bold" style="min-width:250px">
                                <template #body="{ data }">
                                    {{ data.subcat }}
                                </template>
                                <template #filter="{ filterModel, filterCallback }">
                                    <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
                                </template>
                            </Column>

                            <Column field="general_title" header="General Title" sortable frozen class="font-bold" style="min-width:250px">
                                <template #body="{ data }">
                                    {{ data.general_title }}
                                </template>
                                <template #filter="{ filterModel, filterCallback }">
                                    <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
                                </template>
                            </Column>


                            <Column field="country_tag" header="Country" sortable frozen class="font-bold" style="min-width:250px">
                                <template #body="{ data }">
                                    {{ data.country_tag }}
                                </template>
                                <template #filter="{ filterModel, filterCallback }">
                                    <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
                                </template>
                            </Column>

                            <Column field="title" header="Title" sortable frozen class="font-bold" style="min-width:250px">
                                <template #body="{ data }">
                                    {{ data.title }}
                                </template>
                                <template #filter="{ filterModel, filterCallback }">
                                    <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
                                </template>
                            </Column>

                            <Column field="details" header="Details" sortable frozen class="font-bold" style="min-width:250px">
                                <template #body="{ data }">
                                    {{ data.details }}
                                </template>
                                <template #filter="{ filterModel, filterCallback }">
                                    <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
                                </template>
                            </Column>

                            <Column field="created_at" header="Created At" sortable frozen class="font-bold" style="min-width:250px">
                                <template #body="{ data }">
                                    {{ data.created_at }}
                                </template>
                                <template #filter="{ filterModel, filterCallback }">
                                    <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
                                </template>
                            </Column>

                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import axios from 'axios'
    import {FilterMatchMode} from 'primevue/api'
    import ChartDataLabels from 'chartjs-plugin-datalabels';
    import {Chart} from 'chart.js';
    Chart.register(ChartDataLabels);


    export default {
        name:"PostSystemChangeRequestList",
        data(){
            return {
                posts:[],
                filters:{
                    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'id' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    'category' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    'subcat' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    'general_title' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    'priority' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    'due_date' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    'country_tag' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    'title' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    'details' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    'description' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    'remarks' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    'status' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    'requester.username' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    'reviewer.username' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    'assignee.username' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    'approver.username' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    'created_at' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    'flag_overdue' : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                },
                count_by_priority:'',
                count_by_priority_open:'',
                count_by_priority_close:'',
                count_by_overdue_open:'',
                count_by_overdue_close:'',
                count_by_status:'',
                count_by_id:'',
                selected_posts:[],
                chart1Data: null,
                chart1Options: null,
                chart2Data: null,
                chart2Options: null,
                chart3Data: null,
                chart3Options: null,
            }
        },
        mounted(){
            this.getPosts();
        },
        methods:{
            formatDate(date = new Date()) {
                const year = date.toLocaleString('default', {year: 'numeric'});
                const month = date.toLocaleString('default', {month: 'short'});
                const day = date.toLocaleString('default', {day: '2-digit'});

                return [month,year].join('-');
            },
            async getPosts(){
                
                // CountBy function
                const countBy = (arr, fn) =>
                    arr.map(typeof fn === 'function' ? fn : val => val[fn])
                       .reduce((acc, val) => {
                            acc[val] = (acc[val] || 0) + 1;
                            return acc;
                            }, {});
                
                const countBy_open_status = (arr, fn) => 
                     arr.filter(({status}) => {return status ==="New" || status ==="Working_In_Progress" || status ==="Pending_Review" || status === "Pending_Approve"})
                        .map(typeof fn === 'function' ? fn : val => val[fn])
                        .reduce((acc, val) => {
                                acc[val] = (acc[val] || 0) + 1; 
                                return acc;},{});

                const countBy_closed_status = (arr, fn) => 
                     arr.filter(({status}) => {return status ==="Cancelled" || status ==="Completed"})
                        .map(typeof fn === 'function' ? fn : val => val[fn])
                        .reduce((acc, val) => {
                                acc[val] = (acc[val] || 0) + 1; 
                                return acc;},{});

                await axios
                    .get(`/api/v1/post/`, {params:{filter_cat:"General"}})
                    .then(response=>{
                        this.posts=response.data;
                        this.selected_posts=response.data;
                        this.count_by_status=countBy(this.posts, "status");
                        this.count_by_priority=countBy(this.posts, e => e["priority"]);
                        this.count_by_priority_open=countBy_open_status(this.posts, "priority");
                        this.count_by_priority_close=countBy_closed_status(this.posts, "priority");
                        this.count_by_overdue_open=countBy_open_status(this.posts, "flag_overdue");
                        this.count_by_overdue_close=countBy_closed_status(this.posts, "flag_overdue");
                    })
                    .catch(error=>{
                        console.log(error)
                    })
                
                this.chart1Data = this.setChart1Data();
                this.chart1Options = this.setChart1Options();
                this.chart2Data = this.setChart2Data();
                this.chart2Options = this.setChart2Options();
                this.chart3Data = this.setChart3Data();
                this.chart3Options = this.setChart3Options();
            },
            async filter_posts(header,val, status_type){
                
                if (header==="All") {
                    this.selected_posts=this.posts
                } else {
                    if (status_type === "Open"){
                        this.selected_posts = this.posts.filter(i=>{return (i.status ==="New" || i.status ==="Working_In_Progress" || i.status ==="Pending_Review" || i.status === "Pending_Approve")})
                        console.log(this.selected_posts)
                        this.selected_posts = this.selected_posts.filter(i=>{return (i[header]===val)})
                        console.log(this.selected_posts)
                    } else if (status_type === "Close"){
                        this.selected_posts = this.posts.filter(i=>{return (i.status ==="Cancelled" || i.status ==="Completed")})
                        this.selected_posts = this.selected_posts.filter(i=>{return (i[header]===val)})
                    } else {
                        this.selected_posts = this.posts.filter(i=>{return (i[header]===val)})
                    }
                }
            },
            setChart1Data() {
                const documentStyle = getComputedStyle(document.documentElement);

                var formatteddata = this.posts.map((e, i) => {
                    let dateString = e.created_at;
                    let [day, month, year] = dateString.split('/');
                    const dateObj = new Date(+year, +month -1, +day);
                    
                    return {
                        created_by:this.formatDate(dateObj),
                        priority:e.priority,
                    }
                });
                
                var formatteddata = Object.groupBy(formatteddata, ({created_by}) => created_by) // https://stackoverflow.com/questions/40774697/how-can-i-group-an-array-of-objects-by-key

                const groupAndSum = (dict) =>{
                    const tmp = [];
                    for (var key in dict){
                            const countby_priority = dict[key].map(el=>el["priority"])
                                                              .reduce((result, object) => {
                                                                    result[object]=(result[object] || 0) + 1                        
                                                                    return result;}, 
                                                                    {});
                            countby_priority["monthly"] = key
                            tmp.push(countby_priority)
                    } 
                    return tmp;
                }

                var formatteddata = groupAndSum(formatteddata, 'created_by', 'priority')

                var x_month = formatteddata.map(function(e) {
                    return e.monthly
                })

                var y_low= formatteddata.map(function(e) {
                    
                    return e.Low
                })

                var y_medium =formatteddata.map(function(e){
                    return e.Medium
                })

                var y_high =formatteddata.map(function(e){
                    return e.High
                })

                var y_urgent =formatteddata.map(function(e){
                    return e.Urgent
                })


                return {
                    labels: x_month,
                    datasets: [
                        {
                            type: 'bar',
                            label: 'Low',
                            backgroundColor: 'rgba(0, 255, 122, 0.2)',
                            data: y_low
                        },
                        {
                            type: 'bar',
                            label: 'Medium',
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            data: y_medium
                        },
                        {
                            type: 'bar',
                            label: 'High',
                            backgroundColor: 'rgba(255, 222, 0, 0.2)',
                            data: y_high
                        },
                        {
                            type: 'bar',
                            label: 'Urgent',
                            backgroundColor: 'rgba(255, 0, 0, 0.2)',
                            data: y_urgent
                        },
                    ]
                };
            },
            setChart1Options() {
                const documentStyle = getComputedStyle(document.documentElement);
                const textColor = documentStyle.getPropertyValue('--text-color');
                const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
                const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
                // const chartPlugins = [ChartDataLabels]
                return {
                    maintainAspectRatio: false,
                    aspectRatio: 0.8,
                    plugins: {
                        datalabels: {
                            color: '#FF55FF',
                        },
                        tooltips: {
                            mode: 'index',
                            intersect: false
                        },
                        legend: {
                            labels: {
                                color: textColor
                            }
                        },
                        datalabels: {
                            color: 'blue',
                            labels: {
                                value: {
                                    color: 'black',
                                    font: {
                                        weight: 'bold',
                                        size: 20,
                                    },
                                },
                            },
                        },
                    },
                    scales: {
                        x: {
                            
                            stacked: true,
                            ticks: {
                                color: textColorSecondary,
                                font: {
                                    size:20
                                }
                            },
                            grid: {
                                color: surfaceBorder
                            }
                        },
                        y: {
                            stacked: true,
                            ticks: {
                                color: textColorSecondary,
                                font: {
                                    size:20
                                }
                            },
                            grid: {
                                color: surfaceBorder
                            }
                        }
                    }
                };
            },
            setChart2Data() {
                const documentStyle = getComputedStyle(document.documentElement);
                var formatteddata = this.posts.filter(function(post){return (post.status !=="Completed") && (post.status !=="Cancelled") }).map((e, i) => {
                    let dateString = e.created_at;
                    let [day, month, year] = dateString.split('/');
                    const dateObj = new Date(+year, +month -1, +day);
                    
                    let duedateString = e.due_date
                    let [due_year, due_month, due_day] = duedateString.split('-');
                    const duedateObj = new Date(+due_year, +due_month -1, +due_day);
                    return {
                        due_date:this.formatDate(duedateObj),
                        created_by:this.formatDate(dateObj),
                        priority:e.priority,
                        status:e.status,
                    }
                });
                
                var formatteddata = Object.groupBy(formatteddata, ({due_date}) => due_date) // https://stackoverflow.com/questions/40774697/how-can-i-group-an-array-of-objects-by-key

                const groupAndSum = (dict) =>{
                    const tmp = [];
                    for (var key in dict){
                            const countby_priority = dict[key].map(el=>el["priority"])
                                                              .reduce((result, object) => {
                                                                    result[object]=(result[object] || 0) + 1                        
                                                                    return result;}, 
                                                                    {});
                            countby_priority["monthly"] = key
                            tmp.push(countby_priority)
                    } 
                    return tmp;
                }

                var formatteddata = groupAndSum(formatteddata, 'due_date', 'priority')

                var x_month = formatteddata.map(function(e) {
                    return e.monthly
                })

                var y_low= formatteddata.map(function(e) {
                    
                    return e.Low
                })

                var y_medium =formatteddata.map(function(e){
                    return e.Medium
                })

                var y_high =formatteddata.map(function(e){
                    return e.High
                })

                var y_urgent =formatteddata.map(function(e){
                    return e.Urgent
                })


                return {
                    labels: x_month,
                    datasets: [
                        {
                            type: 'bar',
                            label: 'Low',
                            backgroundColor: 'rgba(0, 255, 122, 0.2)',
                            data: y_low
                        },
                        {
                            type: 'bar',
                            label: 'Medium',
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            data: y_medium
                        },
                        {
                            type: 'bar',
                            label: 'High',
                            backgroundColor: 'rgba(255, 222, 0, 0.2)',
                            data: y_high
                        },
                        {
                            type: 'bar',
                            label: 'Urgent',
                            backgroundColor: 'rgba(255, 0, 0, 0.2)',
                            data: y_urgent
                        },
                    ]
                };
            },
            setChart2Options() {
                const documentStyle = getComputedStyle(document.documentElement);
                const textColor = documentStyle.getPropertyValue('--text-color');
                const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
                const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
                // const chartPlugins = [ChartDataLabels]
                return {
                    maintainAspectRatio: false,
                    aspectRatio: 0.8,
                    plugins: {
                        datalabels: {
                            color: '#FF55FF',
                        },
                        tooltips: {
                            mode: 'index',
                            intersect: false
                        },
                        legend: {
                            labels: {
                                color: textColor
                            }
                        },
                        datalabels: {
                            color: 'blue',
                            labels: {
                                value: {
                                    color: 'black',
                                    font: {
                                        weight: 'bold',
                                        size: 20,
                                    },
                                },
                            },
                        },
                    },
                    scales: {
                        x: {
                            
                            stacked: true,
                            ticks: {
                                color: textColorSecondary,
                                font: {
                                    size:20
                                }
                            },
                            grid: {
                                color: surfaceBorder
                            }
                        },
                        y: {
                            stacked: true,
                            ticks: {
                                color: textColorSecondary,
                                font: {
                                    size:20
                                }
                            },
                            grid: {
                                color: surfaceBorder
                            }
                        }
                    }
                };
            },
            setChart3Data() {
                const documentStyle = getComputedStyle(document.documentElement);
                
                const countBy = (arr, fn) =>
                    arr.map(typeof fn === 'function' ? fn : val => val[fn])
                       .reduce((acc, val) => {
                            acc[val] = (acc[val] || 0) + 1;
                            return acc;
                            }, {});
                
                var formatteddata = countBy(this.posts, "status");
                let arr_formatteddata=[]
                let dict_formatteddata={}
                for (var key in formatteddata) {
                    dict_formatteddata["status"]=key
                    dict_formatteddata["count"]=formatteddata[key]
                    arr_formatteddata.push(dict_formatteddata)
                    dict_formatteddata={}
                }

                console.log(arr_formatteddata)
                var x_status = arr_formatteddata.map(function(e) {
                    return e.status

                })

                var y_count = arr_formatteddata.map(function(e) {
                    return e.count

                })


                return {
                    labels: x_status,
                    datasets: [
                        {
                            data:y_count,
                            backgroundColor: ['rgba(0, 255, 122, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(255, 222, 0, 0.2)','rgba(255, 0, 0, 0.2)', 'rgba(255, 100, 0, 0.2)','rgba(255, 0, 100, 0.2)'],
                            hoverBackgroundColor: ['rgba(0, 255, 122, 0.8)', 'rgba(75, 192, 192, 0.8)', 'rgba(255, 222, 0, 0.8)','rgba(255, 0, 0, 0.8)', 'rgba(255, 100, 0, 0.8)','rgba(255, 0, 100, 0.8)']
                        }
                    ]
                };
            },
            setChart3Options() {
                const documentStyle = getComputedStyle(document.documentElement);
                const textColor = documentStyle.getPropertyValue('--text-color');
                const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
                const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
                // const chartPlugins = [ChartDataLabels]
                return {
                    maintainAspectRatio: false,
                    aspectRatio: 0.8,
                    plugins: {
                        datalabels: {
                            color: 'blue',
                            labels: {
                                value: {
                                    color: 'black',
                                    font: {
                                        weight: 'bold',
                                        size: 20,
                                    },
                                },
                            },
                        },
                        tooltips: {
                            mode: 'index',
                            intersect: false
                        },
                        legend: {
                            labels: {
                                color: textColor
                            }
                        },

                    },

                };
            }
        }
    }
</script>

<style scoped>
	/* Some custom styles to beautify this example */
    .content-header{
        padding: 15px;
        background: #dbdfe5;
        text-align: center;
    }
    .content-body{
      padding:15px;
      margin-top:15px;
      background: #909791;
      min-height: 750px;
    }
    .content-header.cust-border{
      border-style: solid;
      margin-bottom:1px;
    }
    .custom_font {
    color: var(--teal-900);; 
    font-weight: 700;
    }   
</style>