<template>
  <div class="grid nested-grid">
      <Toast position="top-center"/>
      <!-- main grid col-11 -->
      <div class="col-11">
          <div class="grid">
              <div class="col-12" style="color: var(--cyan-900); font-weight: 700;">
                <div class="content-body border-round h-full">
                    <!-- Row 1 -->
                    <div class="grid">
                            <!-- Post Details Part 1-->
                            <div class="col-3">
                                <div class="content-header border-round bg-teal-100">
                                    <h1>Post Details Part 1</h1>
                                </div>
                                
                                <div v-show=flag_post_details_part_1_edit>
                                    <Button @click="toggleEditButton('flag_post_details_part_1_edit')" class="m-1" label="Edit" rounded text/>
                                </div>

                                <div v-show=!flag_post_details_part_1_edit>
                                    <Button  @click="cancelEditPostDetails('flag_post_details_part_1_edit')" class="m-1" label="Cancel" rounded text/>   
                                    <Button  @click="editPostDetails('flag_post_details_part_1_edit')" class="m-1" label="Save" rounded text/>
                                </div>

                                <div class="grid">
                                    <div class="col-12" >
                                        <Divider type="dotted"/>
                                            <div class="mt-2">
                                                <div class="p-inputgroup mt-2">
                                                    <p style="width: 5em">Overdue</p>
                                                    <span class="p-inputgroup-addon ml-1">
                                                        <i class="pi pi-info-circle"></i>
                                                    </span>
                                                    <InputText :v-model="post.flag_overdue?'Yes':'No'" 
                                                                class='custom_font'
                                                                disabled
                                                                :placeholder="post.flag_overdue?'Yes':'No'"  />
                                                </div>

                                                <div class="p-inputgroup mt-2">
                                                    <p style="width: 5em">Status</p>
                                                    <span class="p-inputgroup-addon ml-1">
                                                        <i class="pi pi-info-circle"></i>
                                                    </span>
                                                    <InputText v-model=post.status 
                                                                class='custom_font'
                                                                disabled
                                                                :placeholder=post.status />
                                                </div>
                                                
                                                <div class="p-inputgroup mt-2">
                                                    <p style="width: 5em">Category</p>
                                                    <span class="p-inputgroup-addon ml-1">
                                                        <i class="pi pi-info-circle"></i>
                                                    </span>
                                                    <InputText v-model=post.category 
                                                                class='custom_font'
                                                                disabled 
                                                                :placeholder=post.category />
                                                </div>
                                                
                                                <div class="p-inputgroup mt-2">
                                                    <p style="width: 5em">Sub Category</p>
                                                    <span class="p-inputgroup-addon ml-1">
                                                        <i class="pi pi-info-circle"></i>
                                                    </span>
                                                    <Dropdown   v-model=subcat
                                                                class='custom_font'
                                                                :disabled="flag_post_details_part_1_edit ? true: false" 
                                                                :options="type_subcat" 
                                                                optionLabel="type" 
                                                                :placeholder="post.subcat" /> 
                                                </div>
                                                
                                                <div class="p-inputgroup mt-2">
                                                    <p style="width: 5em">Priority</p>
                                                    <span class="p-inputgroup-addon ml-1">
                                                        <i class="pi pi-info-circle"></i>
                                                    </span>
                                                    <Dropdown   v-model=priority
                                                                class='custom_font'
                                                                :disabled="flag_post_details_part_1_edit ? true: false" 
                                                                :options="type_priority" 
                                                                optionLabel="type" 
                                                                :placeholder="post.priority"/> 
                                                </div>

                                                <div class="p-inputgroup mt-2">
                                                    <p style="width: 5em">Due Date</p>
                                                    <span class="p-inputgroup-addon ml-1">
                                                        <i class="pi pi-info-circle"></i>
                                                    </span>
                                                   
                                                    <Calendar v-model=due_date
                                                              class='custom_font'
                                                              :disabled="flag_post_details_part_1_edit ? true: false"  
                                                              dateFormat="dd/mm/yy"
                                                              placeholder=post.due_date  />
                                                </div>

                                                <div class="p-inputgroup mt-2">
                                                    <p style="width: 5em">Requester</p>
                                                    <span class="p-inputgroup-addon ml-1">
                                                        <i class="pi pi-info-circle"></i>
                                                    </span>
                                                    
                                                    <InputText  :v-model="requester" 
                                                                class='custom_font'
                                                                disabled
                                                                :placeholder="requester.username" /> 
                                                </div>


                                                <div class="p-inputgroup mt-2">
                                                    <p style="width: 5em">Assignee</p>
                                                    <span class="p-inputgroup-addon ml-1">
                                                        <i class="pi pi-info-circle"></i>
                                                    </span>
                                                    <Dropdown v-model=assignee 
                                                                class='custom_font'
                                                                :disabled="flag_post_details_part_1_edit ? true: false" 
                                                                :options="type_users" 
                                                                optionLabel="username" 
                                                                :placeholder=assignee.username /> 
                                                </div>
                                                
                                                <div class="p-inputgroup mt-2">
                                                    <p style="width: 5em">Reviewer</p>
                                                    <span class="p-inputgroup-addon ml-1">
                                                        <i class="pi pi-info-circle"></i>
                                                    </span>
                                                    <Dropdown v-model=reviewer 
                                                                class='custom_font'
                                                                :disabled="flag_post_details_part_1_edit ? true: false" 
                                                                :options="type_users" 
                                                                optionLabel="username" 
                                                                :placeholder=reviewer.username /> 
                                                </div>

                                                <div class="p-inputgroup mt-2">
                                                    <p style="width: 5em">Approver</p>
                                                    <span class="p-inputgroup-addon ml-1">
                                                        <i class="pi pi-info-circle"></i>
                                                    </span>
                                                    <Dropdown v-model=approver 
                                                                class='custom_font'
                                                                :disabled="flag_post_details_part_1_edit ? true: false" 
                                                                :options="type_users" 
                                                                optionLabel="username" 
                                                                :placeholder=approver.username />                                           
                                                </div>

                                                <div class="p-inputgroup mt-2">
                                                    <p style="width: 5em">Country</p>
                                                    <span class="p-inputgroup-addon ml-1">
                                                        <i class="pi pi-info-circle"></i>
                                                    </span>
                                                    
                                                    <InputText v-model=country_tag 
                                                                class='custom_font'
                                                                :disabled="flag_post_details_part_1_edit ? true: false" 
                                                                placeholder=post.country_tag /> 
                                                </div>
                                            </div>
                                        <Divider type="dotted"/>
                                    </div>
                                </div>

                            </div>
                            <!-- Post Details Part 2 -->
                            <div class="col-5">
                                <div class="content-header border-round bg-teal-100">
                                    <h1>Post Details Part 2</h1>
                                </div>
                                <div v-show=flag_post_details_part_1_edit>
                                    <Button @click="toggleEditButton('flag_post_details_part_1_edit')" class="m-1" label="Edit" rounded text/>
                                </div>
                                <div v-show=!flag_post_details_part_1_edit>
                                    <Button  @click="cancelEditPostDetails('flag_post_details_part_1_edit')" class="m-1" label="Cancel" rounded text/>   
                                    <Button  @click="editPostDetails('flag_post_details_part_1_edit')" class="m-1" label="Save" rounded text/>
                                </div>
                                <div class="grid">
                                    <div class="col-12">
                                        <Divider type="dotted"/>
                                            <div v-show=flag_post_details_part_1_edit>
                                                <ScrollPanel style="width: 100%; height: 529px" >
                                                    <Panel header="Title">
                                                        <p class="m-0" >
                                                            {{title}}
                                                        </p>
                                                    </Panel>
                                                    
                                                    <Panel header="Details" >
                                                        <p class="m-0">
                                                            {{details}}
                                                        </p>
                                                    </Panel>
                                                    <Panel header="Description">
                                                        <p class="m-0">
                                                            {{description}}
                                                        </p>
                                                    </Panel>
                                                    <Panel header="Remarks">
                                                        <p class="m-0">
                                                            {{remarks}}
                                                        </p>
                                                    </Panel>
                                                </ScrollPanel>
                                            </div>


                                            <div v-show=!flag_post_details_part_1_edit>
                                                <h4>Title</h4>
                                                <div class="card flex justify-content-left" >
                                                        <Textarea v-model="title" rows="5" cols="60" />
                                                </div>
                                                <h4>Details</h4>
                                                <div class="card flex justify-content-left" >
                                                        <Textarea v-model="details" rows="5" cols="60" />
                                                </div>
                                                <h4>Description</h4>
                                                <div class="card flex justify-content-left" >
                                                        <Textarea v-model="description" rows="5" cols="60" />
                                                </div>
                                                <h4>Remarks</h4>
                                                <div class="card flex justify-content-left" >
                                                        <Textarea v-model="remarks" rows="5" cols="60" />
                                                </div>
                                            </div>
                            
                                        <Divider type="dotted"/>
                                    </div>
                                </div>
                            </div>
                            <!-- Action Item -->        
                            <div class="col-4">
                                <div class="content-header border-round bg-teal-100">
                                    <h1>Action Item</h1>
                                </div>
                                <Button @click="toggleEditButton('flag_new_action_item')" class="m-1" label="Add New Action Item" rounded text/>
                                <Button :label="expand_collapse === -1? 'Expand All' : 'Collapse All'" @click="expandOrCollapse()" rounded severity="primary" text class="col-3 col-offset-4 mb-2"/> 
                                <div class="grid">
                                    <div class="col-12">
                                        <Divider type="dotted"/>
                                        <div v-show="flag_new_action_item" >
                                            <span class="p-float-label">
                                                <div>
                                                    <p style="width: 20em">New Action Item Title</p>
                                                    <InputText v-model=new_action_item_title class='col-12 mt-1 custom_font' placeholder="Title" maxlength="50" /> 
                                                </div>
                                                <div>
                                                    <p style="width: 20em" >New Action Item Details</p>
                                                    <Textarea v-model=new_action_item_details autoResize rows="5" cols="30" class="col-12 mt-1 custom_font" placeholder="Details" maxlength="200"/>
                                                </div>
                                            </span>
                                            
                                            <Button label="Add" @click="addNewActionItem('flag_new_action_item')" rounded severity="primary" text class="col-3 col-offset-4 mb-2"/> 
                                            
                                        </div>
                                        
                                        
                                        <!-- if action items is NA, then show this div -->
                                        <template v-if="!actionitems.length">
                                            <Card style="text-align: center;" class="m-2">
                                                <template #title>Action Item is not available!</template>
                                                <template #content>
                                                    <p class="m-0">
                                                        Please click "Add Action Item" at the top
                                                    </p>
                                                </template>
                                            </Card>
                                        </template>
                                            
                                            
                                        <ScrollPanel style="width: 100%; height: 529px">
                                            <div v-for="item in actionitems" v-bind:key="item.id" style="text-align: left;">
                                                <Accordion :activeIndex=expand_collapse>
                                                    <AccordionTab :header=item.title>
                                                        <p class="m-0">
                                                            <strong>Created By: </strong>{{item.created_by.username}}
                                                            <br>
                                                            <strong>Created At: </strong>{{item.created_at}}
                                                            <br>
                                                            <strong>Details: </strong>{{item.details}}
                                                        </p>
                                                    </AccordionTab>
                                                </Accordion>
                                            </div>
                                        </ScrollPanel>
                                        <Divider type="dotted"/>
                                    </div>
                                </div>
                            </div>
                    </div>
                    
                    
                    <!-- Row 2 -->
                    <div class="grid">
                        <!-- Progress Log -->
                        <div class="col-3">
                            <div class="content-header border-round bg-teal-100">
                                <h1>Progress Log</h1>
                            </div>
                            <Button @click="toggleEditButton('flag_new_progress_log')" class="m-1" :label="flag_new_progress_log?'Cancel':'Update Progress Log'" rounded text/>
                            <div class="grid">
                                <div class="col-12" >
                                    <Divider type="dotted"/>
                                    
                                    <div v-show="flag_new_progress_log" >
                                        <span class="p-float-label">
                                            <div>
                                                <p style="width: 20em">Date</p>
                                                <Calendar v-model=new_progress_log_date
                                                            class='custom_font'
                                                            dateFormat="dd/mm/yy"/>
                                            </div>
                                            <div>
                                                <p style="width: 20em" >Title</p>
                                                <Textarea v-model=new_progress_log_title autoResize rows="5" cols="30" class="col-12 mt-1 custom_font" placeholder="Type here" maxlength="200"/>
                                            </div>
                                        </span>
                                        
                                        <Button label="Add" @click="addNewProgressLog('flag_new_progress_log')" rounded severity="primary" text class="col-3 col-offset-4 mb-2"/> 
                                        
                                    </div>
                                    
                                    
                                    <!-- if progress log is NA, then show this div -->
                                    <template v-if="!progresses.length">
                                        <Card style="text-align: center;" class="m-2">
                                            <template #title>Progress Log is not available!</template>
                                            <template #content>
                                                <p class="m-0">
                                                    Please click "Add Progress" at the top
                                                </p>
                                            </template>
                                        </Card>
                                    </template>
                                    
                                    <ScrollPanel style="width: 100%; height: 529px">
                                        <div class="card m-2">
                                            <Timeline :value="progresses">
                                                <template #opposite="slotProps">
                                                    <small class="p-text-secondary">{{slotProps.item.action_date}}</small>
                                                </template>
                                                <template #content="slotProps">
                                                    {{slotProps.item.title}}
                                                </template>
                                            </Timeline>
                                        </div>
                                    </ScrollPanel>

                                    <Divider type="dotted"/>
                                </div>
                            </div>
                        </div>
                        <!-- Post Comment -->
                        <div class="col-6">
                            <div class="content-header border-round bg-teal-100">
                                <h1>User Comment</h1>
                            </div>
                            <Button @click="toggleEditButton('flag_new_comment')" class="m-1" :label="flag_new_comment?'Cancel':'Add Comment'" rounded text/>
                            <div class="grid">
                                <div class="col-12">
                                    <Divider type="dotted"/>
                                    <div v-show="flag_new_comment" >
                                        <span class="p-float-label">
                                            <div>
                                                <p style="width: 20em" >Title</p>
                                                <InputText v-model=new_comment_title class='col-12 mt-1 custom_font' placeholder="Title" maxlength="50" /> 
                                            </div>
                                            <div>
                                                <p style="width: 20em" >Details</p>
                                                <Textarea v-model=new_comment_details autoResize rows="5" cols="30" class="col-12 mt-1 custom_font" placeholder="Type here" maxlength="200"/>                            
                                            </div>
                                        </span>
                                        
                                        <Button label="Add" @click="addNewComment('flag_new_comment')" rounded severity="primary" text class="col-3 col-offset-4 mb-2"/> 

                                    </div>

                                    <!-- if comments is NA, then show this div -->
                                    <template v-if="!comments.length">
                                        <Card style="text-align: center;" class="m-2">
                                            <template #title>Comments is not available!</template>
                                            <template #content>
                                                <p class="m-0">
                                                    Please click "Add Comment" at the top
                                                </p>
                                            </template>
                                        </Card>
                                    </template>
                                    

                                    <ScrollPanel style="width: 100%; height: 700px">
                                        <div v-for="comment in comments" v-bind:key="comment.id" style="text-align: left;">
                                            <Fieldset class="m-1">
                                                <template #legend>
                                                    <div class="flex align-items-center gap-2 px-2">
                                                        <span class="pi pi-tag"></span>
                                                        <span>{{comment.id}}</span>
                                                        <span class="pi pi-info-circle"></span>
                                                        <span>{{comment.title}}</span>
                                                        <span class="pi pi-user"></span>
                                                        <span>{{comment.created_by.username}}</span>
                                                        <span class="pi pi-clock"></span>
                                                        <span>{{comment.created_at}}</span>
                                                    </div>
                                                </template>
                                                <div style="text-align: left;">
                                                    <pre>
{{comment.detail}}
                                                    </pre>
                                                </div>
                                            </Fieldset>
                                        </div>
                                    </ScrollPanel>
                                    
                                    <Divider type="dotted"/>
                                </div>
                            </div>
                        </div>
                        <!-- History Log -->        
                        <div class="col-3">
                            <div class="content-header border-round bg-teal-100">
                                <h1>Histoy Log</h1>
                            </div>
                            <div class="grid">
                                <div class="col-12">
                                    <Divider type="dotted"/>
                                    
                                    <ScrollPanel style="width: 100%; height: 700px">
                                        <div class="card m-2">
                                            <Timeline :value="historylog"  align="alternate" class="customized-timeline">
                                                <template #content="slotProps">
                                                    <Card class="mt-3">
                                                        <template #title>
                                                            {{ slotProps.item.created_by.username}}
                                                        </template>
                                                        <template #subtitle>
                                                            {{ slotProps.item.created_at }}
                                                        </template>
                                                        <template #content>
                                                            {{slotProps.item.title}}
                                                        </template>
                                                    </Card>
                                                </template>
                                            </Timeline>
                                        </div>
                                    </ScrollPanel>
                                    <Divider type="dotted"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
          </div>
      </div>
      <div class="col-1">
        <template v-if="post.status==='New'">
            <Button @click="updateStatus('Working_In_Progress', 'To Acknowledge1')" label="To Acknowledge" rounded text/>
            <Divider type="dotted"/>
        </template>

        <template v-if="post.status==='Working_In_Progress'">
            <Button @click="updateStatus('Pending_Review', 'Submit to Review')"  label="Submit to Review" rounded text/>
            <Divider type="dotted"/>
        </template>

        <template v-if="post.status==='Pending_Review'">
            <Button @click="updateStatus('Pending_Approve', 'Submit to Arppove')"  label="Submit to Approve" rounded text/>
            <Divider type="dotted"/>
            <Button @click="updateStatus('Working_In_Progress', 'Reject & Pass to Assignee')"  label="Reject & Pass to Assignee" severity="danger" rounded text/>
            <Divider type="dotted"/>
        </template>

        <template v-if="post.status==='Pending_Approve'">
            <Button @click="updateStatus('Completed', 'Approved & Completed')"  label="Approved & Completed" rounded text/>
            <Divider type="dotted"/>
            <Button @click="updateStatus('Working_In_Progress', 'Reject & Pass to Assignee')" label="Reject & Pass to Assignee" severity="danger" rounded text/>
            <Divider type="dotted"/>
            <Button @click="updateStatus('Pending_Review', 'Reject & Pass to Reviewer')"  label="Reject & Pass to Reviewer" severity="danger" rounded text/>
            <Divider type="dotted"/>
        </template>


        <template v-if="post.status !=='Cancelled' && post.status !=='Completed'">
            <Button @click="updateStatus('Cancelled', 'Submit to Cancel')"  label="Submit to Cancel" severity="warning" rounded text/>
            <Divider type="dotted"/>
        </template>
      </div>
  </div>


</template>


<script>

    import axios from 'axios'
    
    export default {
        name:'PostSystemBugDetails',
        props:{},
        components:{},
        data(){
            return{
                post_backup:'',
                post:'',
                comments:'',
                progresses:'',
                actionitems:'',
                historylog:'',
                flag_post_details_part_1_edit:true,
                flag_post_details_part_2_edit:false,
                flag_new_action_item:false,
                flag_new_progress_log:false,
                flag_new_comment:false,
                new_action_item_title:'',
                new_action_item_details:'',
                expand_collapse:-1,
                new_progress_log_date:'',
                new_progress_log_title:'',
                new_comment_title:'',
                new_comment_details:'',
                due_date:'',
                priority:'',
                subcat:'',
                country_tag:'',
                title:'',
                details:'',
                description:'',
                remarks:'',
                approver:{},
                assignee:{},
                reviewer:{},
                requester:{},
                type_category:[
                    {type: "System_Change_Request"}, 
                    {type: "System_Bug"},
                    {type: "General"},
                    {type: "Ad_Hoc_Requests"},
                    {type: "Routine_Tasks"}
                ],
                type_subcat:[
                    {type: "Module_A"}, 
                    {type: "Module_B"},
                    {type: "Module_C"},
                    {type: "Module_D"},
                    {type: "Module_E"}
                ],
                type_priority:[
                    {type: "Low"}, 
                    {type: "Medium"},
                    {type: "High"},
                    {type: "Urgent"},
                ],
                type_country:[],
                type_users:[],

            }
        },
        mounted(){
            this.getBasicInfo()
        },
        computed:{

        },
        watch:{},
        methods:{
            formatDate(date) {
                    const year = date.toLocaleString('default', {year: 'numeric'});
                    const month = date.toLocaleString('default', {month: '2-digit',});
                    const day = date.toLocaleString('default', {day: '2-digit'});
                    
                    return [year, month, day].join('-');
            },
            async updateStatus(type, buttonlabel){
                const postID = this.$route.params.id
                var updatedPost= {}
                var newhistoryLog={}
                //  only the requester is allowed to cancal
                if (type==="Cancelled"){
                    if (this.requester.username !== this.$store.state.user.username){
                         this.$toast.add({ severity: 'warn', summary: 'Warning Message', detail: "Only " + this.requester.username + " is allowed to click 'Cancelled' button", life: 3000 });
                        return
                    }
                }
                // only the assignee can click the "To Acknowledge" button
                if (this.post.status==="New"){
                    if (type==="Working_In_Progress"){
                        if (this.assignee.username !== this.$store.state.user.username){
                            this.$toast.add({ severity: 'warn', summary: 'Warning Message', detail: "Only " + this.assignee.username + " is allowed to click 'To Acknowledge'", life: 3000 });
                            return
                        }
                    } 
                }

                if (this.post.status==="Working_In_Progress"){
                    if (this.assignee.username !== this.$store.state.user.username){
                        this.$toast.add({ severity: 'warn', summary: 'Warning Message', detail: "Only " + this.assignee.username + " is allowed to click 'Submit to Review' button", life: 3000 });
                        return
                    }
                }

                if (this.post.status==="Pending_Review"){
                    if (this.reviewer.username !== this.$store.state.user.username){
                        this.$toast.add({ severity: 'warn', summary: 'Warning Message', detail: "Only " + this.reviewer.username + " is allowed to click 'Submit to Approve/Reject & Pass To Assignee' button", life: 3000 });
                        return
                    }
                }

                if (this.post.status==="Pending_Approve"){
                    if (this.approver.username !== this.$store.state.user.username){
                        this.$toast.add({ severity: 'warn', summary: 'Warning Message', detail: "Only " + this.approver.username + " is allowed to click 'Submit to Approve/Reject & Pass To Assignee/Reject & Pass to Reviewer' button", life: 3000 });
                        return
                    }
                }

                updatedPost.status=type

                await axios
                    .patch(`/api/v1/post/${postID}/`, updatedPost)
                    .then(response => {  
                        this.toggleEditButton(type)  
                    })
                    .catch(error=>{
                        this.$toast.add({severity:"error", summary: 'Failed Message', detail:error, life: 5000 });
                    })                

                newhistoryLog.post=this.$route.params.id
                newhistoryLog.created_by=parseInt(this.$store.state.user.id)
                if (type==="Working_In_Progress"){
                    newhistoryLog.title= " clicked this '" + buttonlabel + "' button and the status from "+ this.post.status + " change to " + type
                } else if (type==="Pending_Review"){
                    newhistoryLog.title= " clicked this '" + buttonlabel + "' button and the status from "+ this.post.status + " change to " + type
                } else if (type==="Pending_Approve"){
                    newhistoryLog.title= " clicked this '" + buttonlabel + "' button and the status from "+ this.post.status + " change to " + type
                } else if (type==="Completed"){
                    newhistoryLog.title= " clicked this '" + buttonlabel + "' button and the status from "+ this.post.status + " change to " + type
                } else if (type==="Cancelled"){
                    newhistoryLog.title= " clicked this '" + buttonlabel + "' button and the status from "+ this.post.status + " change to " + type
                }

                await axios
                    .post(`/api/v1/historylog/`, newhistoryLog)
                    .then(response=>{
                        this.$router.push({ name: 'PostSystemBugList'})
                        this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Updated Post Status', life: 3000 });
                    })
                    .catch(error=>{
                        console.log(error)
                    })

            },
            async expandOrCollapse(){
                if (this.expand_collapse=== -1){
                    this.expand_collapse= 0
                } else {
                    this.expand_collapse= -1
                }
            },
            async toggleEditButton(type) {
                if (type ==="flag_post_details_part_1_edit"){
                    if (this.post.status !== "New"){
                        this.$toast.add({severity:"warn", summary: 'Warning Message', detail:"Details is not allowed anymore", life: 5000 });
                        return 
                    }
                }
                
                if (type === "flag_new_action_item"){
                    if (this.post.status !== "New" && this.post.status !== "Working_In_Progress"){
                        this.$toast.add({severity:"warn", summary: 'Warning Message', detail:"New Action Item disallowed to add as submitted to review/approve or completed/cancelled", life: 5000 });
                        return 
                    }
                }

                this[type] = !this[type]
            },
            async addNewComment(type){
                // validate the value of the input
                if (this.new_comment_title==="") {
                    this.$toast.add({ severity: 'warn', summary: 'Missing Value', detail: 'Please Fill Up Comment Title ', life: 3000 });
                    return
                }
                if (this.new_comment_details==="") {
                    this.$toast.add({ severity: 'warn', summary: 'Missing Value', detail: 'Please Fill Up Comment Details ', life: 3000 });
                    return
                }  

                this[type] = !this[type]
                
                const postID = this.$route.params.id
                const newComment = {
                    post:postID,
                    title:this.new_comment_title,
                    detail:this.new_comment_details,
                    created_by:parseInt(this.$store.state.user.id)
                }

                await axios 
                    .post(`/api/v1/comment/`,newComment)
                    .then(response=>{
                        const commentID = response.data.id
                        axios
                            .get(`/api/v1/comment/${commentID}/`)
                            .then(response=>{
                                this.comments.unshift(response.data)
                                this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Created new comment', life: 3000 }); 
                            })
                            .catch(error=>{
                                console.log(error)
                            })

                        this[type] = !this[type]
                        this.new_comment_title=''
                        this.new_comment_details=''
                    })
                    .catch(error=>{
                        // console.log(error)
                        this.$toast.add({severity:"error", summary: 'Failed Message', detail:error, life: 5000 });
                    })
            },
            async addNewProgressLog(type){
                // validate the value of the input
                if (this.new_progress_log_title==="") {
                    this.$toast.add({ severity: 'warn', summary: 'Missing Value', detail: 'Please Fill Up New Progress Log Title ', life: 3000 });
                    return
                }
                if (this.new_progress_log_date==="") {
                    this.$toast.add({ severity: 'warn', summary: 'Missing Value', detail: 'Please Fill Up New Action Date  ', life: 3000 });
                    return
                }

                const postID = this.$route.params.id
                const newProgressLog = {
                    post:postID,
                    title:this.new_progress_log_title,
                    action_date:this.formatDate(this.new_progress_log_date),
                    created_by:  parseInt(this.$store.state.user.id)
                }

                await axios
                    .post(`/api/v1/progress/`,newProgressLog)
                    .then(response=>{
                        this[type] = !this[type]
                        this.new_progress_log_title=''
                        this.action_date=''
                        this.progresses.push(newProgressLog)
                        this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Created new progress log  ', life: 3000 }); 
                    })
                    .catch(error=>{
                        this.$toast.add({severity:"error", summary: 'Failed Message', detail:error, life: 5000 });
                    })

            
            },
            async addNewActionItem(type){
                // validate the value of the input
                if (this.new_action_item_title==="") {
                    this.$toast.add({ severity: 'warn', summary: 'Missing Value', detail: 'Please Fill Up New Action Item Title  ', life: 3000 });
                    return
                }
                const postID = this.$route.params.id
                const newActionItem = {
                    post: postID,
                    title: this.new_action_item_title,
                    details: this.new_action_item_details,
                    created_by: parseInt(this.$store.state.user.id)
                }

                await axios
                    .post(`/api/v1/actionitem/`, newActionItem)
                    .then(response=>{
                        const actionitemID = response.data.id
                                          
                        axios
                            .get(`/api/v1/actionitem/${actionitemID}/`)
                            .then(response=>{
                                this.actionitems.push(response.data)
                                this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Created new action item   ', life: 3000 }); 
                            })
                            .catch(error=>{
                                console.log(error)
                            })
                        this.toggleEditButton(type)
                        this.new_action_item_title=''
                        this.new_action_item_details=''
                    })
                    .catch(error=>{
                        this.$toast.add({ severity: 'error', summary: 'Failed Message', detail: error, life: 5000 });
                    })
            },
            async cancelEditPostDetails(type){
                this.toggleEditButton(type)
                this.subcat = this.post.subcat
                this.priority = this.post.priority
                this.approver=this.post.approver
                this.assignee=this.post.assignee
                this.reviewer=this.post.reviewer
                this.requester=this.post.requester
                this.due_date=this.post.due_date
                this.country_tag=this.post.country_tag
                this.title=this.post.title
                this.details=this.post.details
                this.description=this.post.description
                this.remarks=this.post.remarks

            },
            async editPostDetails(type){
                const postID = this.$route.params.id
                var updatedPost= Object.assign({}, this.post)

                updatedPost.approver=this.approver.id
                updatedPost.assignee=this.assignee.id
                updatedPost.reviewer=this.reviewer.id
                updatedPost.requester=this.requester.id
                updatedPost.priority=this.priority.type ? this.priority.type : this.post.priority
                updatedPost.subcat=this.subcat.type ? this.subcat.type : this.post.subcat
                updatedPost.due_date=this.due_date === updatedPost.due_date ? updatedPost.due_date : this.formatDate(this.due_date)
                
                if (new Date(updatedPost.due_date) < new Date()){
                    updatedPost.flag_overdue=true
                    this.post.flag_overdue=updatedPost.flag_overdue
                } else {
                    updatedPost.flag_overdue=false
                    this.post.flag_overdue=updatedPost.flag_overdue
                }

                await axios
                    .patch(`/api/v1/post/${postID}/`, updatedPost)
                    .then(response => {
                        this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Updated Post Detail', life: 3000 });
                        this.toggleEditButton(type)
                    })
                    .catch(error=>{
                        this.$toast.add({severity:"error", summary: 'Failed Message', detail:error, life: 5000 });
                    })
            },
            async getBasicInfo(){
                
                const postID = this.$route.params.id
                
                // get the post
                await axios
                    .get(`/api/v1/post/${postID}`)
                    .then(response => {
                        console.log(response)
                        this.post=response.data
                        
                        this.approver=this.post.approver
                        this.assignee=this.post.assignee
                        this.reviewer=this.post.reviewer
                        this.requester=this.post.requester
                        this.due_date=this.post.due_date
                        this.country_tag=this.post.country_tag
                        this.title=this.post.title
                        this.details=this.post.details
                        this.description=this.post.description
                        this.remarks=this.post.remarks

                    })
                    .catch(error => {
                        console.log(error)
                    })
                // get the comment
                await axios
                    .get(`/api/v1/comment/?post_id=${postID}`)
                    .then(response=>{
                        this.comments=response.data
                    })
                    .catch(error=>{
                        console.log(error)
                    })
                // get the progress
                await axios
                    .get(`/api/v1/progress/?post_id=${postID}`)
                    .then(response=>{
                        this.progresses=response.data
                    })
                    .catch(error=>{
                        console.log(error)
                    })
                // get the action item
                await axios
                    .get(`/api/v1/actionitem/?post_id=${postID}`)
                    .then(response=>{
                        this.actionitems=response.data
                    })
                    .catch(error=>{
                        console.log(error)
                    })
                // get the list of user from backend
                await axios
                .get(`/api/v1/users/`)
                .then(response=>{
                    this.type_users=response.data

                })
                .catch(error=> {
                    console.log(error)
                })
                 // get the list of country from backend
                await axios
                .get(`/api/v1/country/`)
                .then(response=>{
                    this.type_country=response.data
                    // this.country_tag=response.data
                })
                .catch(error=> {
                    console.log(error)
                })
                // get the list of history item
                await axios
                .get(`/api/v1/historylog/?post_id=${postID}`)
                .then(response=>{
                    this.historylog=response.data
                })
                .catch(error=>{console.log(error)})

            },
        },
        emits:[],
    }

</script>

<style  lang="scss" scoped>
	/* Some custom styles to beautify this example */
    .content-header{
        padding: 15px;
        background: #dbdfe5;
        text-align: center;
    }
    .content-body{
      padding:10px;
      margin-top:10px;
      background: white;
      min-height: 350px;
    }
    .content-header.cust-border{
      border-style: solid;
      margin-bottom:1px;
    }

    .custom_font {
    color: var(--teal-900);; 
    font-weight: 700;
    }   

    .p-panel-content {
    padding: 0 !important;
    }

    pre::first-line {
        text-align:  left;
    }
</style>