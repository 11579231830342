<template>

<div class="grid nested-grid">
    <div class="col-12">
        <div class=" flex flex-wrap align-items-center justify-content-center ">
            <div class="zoomindown 
                        animation-duration-1000 
                        animation-iteration-1 
                        flex align-items-center 
                        justify-content-center 
                        font-bold 
                        border-round 
                        main">
            Imagination Creates Reality
            </div>
        </div>
    </div>
</div>

</template>

<script>
    export default {
        name: "HomeView"
    }

</script>

<style scoped>

.main {
    height: 300px;
    width: 100%;
    font-family: Papyrus;
    position: fixed;
    top: 30%;
    font-size: 100px;
    margin-top: -100px;
    color: black;

}
</style>